.button.is-primary{
    transition: .2s ease-in-out;

    @include theme($themes){
        background: variable('primaryBackground') !important;
        color: variable('secondaryText') !important;
    }

    &:hover{
        @include theme($themes){
            background: variable('primaryHoverBackground') !important;
        }
    }
}

.button{
    font-family: $poppins !important;
    border-radius: 6px;
    box-shadow: none !important;
    outline: 0 !important;
    height: 44px !important;
}

.button.is-loading{
    span{
        color: transparent !important;
    }
}

.button.is-link-button{
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    @include theme($themes){
        color: variable('pageText') !important;
    }    
    
    transition: .2s ease-in-out;

    &:hover{
        cursor: pointer;
        
        @include theme($themes){
            color: variable('primaryText') !important;
        }
    }
}

.button.is-config{
    @include theme($themes){
        background-color: variable('pageBackground') !important;
        color: variable('pageText') !important;
        box-shadow: variable('itemShadow') !important;
    }
    //background: $pageBackground !important;
   // color: white;
    font-weight: bold;
    border: none !important;
    //border: 1px solid transparent !important;
    //box-shadow: none !important;
    transition: .2s ease-in-out;

    &:hover{
        @include theme($themes){
            //border-color: variable('primaryBackground') !important;
            color: variable('primaryText') !important;
        }
    }

    &.warning-red {

        &:hover {
            color: $themeRed !important;
        }
    }
}

.button.is-premium{
    background-color: rgba($premium, .08) !important;
    border: 1px solid rgba($premium, .8);
    max-height: 60px;
    border-radius: 6px;
    box-shadow: $shadow;
    color: rgba($premium, .9);
    transition: .2s ease-in-out;
    font-weight: bold;

    &:hover{
        border-color: $premium !important;
        color: $premium !important;
    }
}