.level-rewards{


    .reward-item{
        width: 100%;
         @include theme($themes){
            background-color: variable('pageBackground') !important;
            box-shadow: variable('itemShadow') !important;
        }
        // background-color: $pageBackground;
        // box-shadow: $shadow;
        border-radius: 6px;
        padding: 5px 5px 5px 0px;
        margin-bottom: 10px;

        .item-spacer{
            height: 28px;
            width: 1px;
            margin: 2px 10px 0px 10px;
            @include theme($themes){
                background-color: rgba(variable('pageText'), .12) !important;
            }
            //background-color: rgba(255, 255, 255, .);
        }

        .delete-item{
            padding: 7.5px 10px 0 0 !important;
            @include theme($themes){
                color: rgba(variable('pageText'), .6) !important;
            }
            transition: .2s ease-in-out;

            &:hover{
                cursor: pointer;
                @include theme($themes){
                    color: variable('primaryText') !important;
                }
            }
        }
    }

    .reward-button{
        width: 250px;
        @include theme($themes){
            background-color: variable('pageBackground') !important;
            box-shadow: variable('itemShadow') !important;
            color: variable('pageText');
        }
        //background-color: $pageBackground;
        //box-shadow: $shadow;
        border-radius: 6px;
        padding: 15.5px 15px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        transition: .2s ease-in-out;
        //color: rgba(white, .9);

        &:hover{
            @include theme($themes){
                color: variable('primaryText');
            }
        }
    }
}

.reward-item-enter {
  opacity: 0;
}
.reward-item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.reward-item-exit {
  opacity: 1;
}
.reward-item-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-in;
}