.knobs, .layer{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.toggle-button{
    position: relative;
    top: 50%;
    width: 64px;
    height: 30px;
    margin: 0 auto 0 auto;
    overflow: hidden;

    &:hover{
        .layer{
            @include theme($themes){
                background-color: darken(variable('pageBackground'), 7%) !important;
            }
        }
    }
}

.toggle-button.r, .toggle-button.r .layer{
    border-radius: 100px;
}

.toggle-button.b2{
    border-radius: 2px;
}

.checkbox{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
}

.knobs{
    z-index: 2;
}

.layer{
    border: 0.5px solid transparent;
    width: 100%;
    @include theme($themes){
        background-color: darken(variable('pageBackground'), 5%);
    }
   // background-color: rgba(11, 11, 11, .3);
    transition: 0.3s ease all;
    z-index: 1;
}

#button-3 .knobs:before{
    position: absolute;
    top: 4px;
    left: 4px;
    width: 22px;
    height: 22px;
    //color: #fff;
    font-weight: 900;
    font-family: 'Material Icons Round';
    content: '\e5cd';
    font-size: 12px;
    line-height: 1.9;
    text-align: center;
    @include theme($themes){
        background-color: variable('pageBackground');
        color: variable('pageText') !important;
    }
    //background-color: rgba(255, 255, 255, .2);
    border-radius: 50%;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#button-3 .checkbox:active + .knobs:before{
    width: 46px;
    border-radius: 100px;
}

#button-3 .checkbox:checked:active + .knobs:before{
    margin-left: -26px;
}

#button-3 .checkbox:checked + .knobs:before{
    content: '\e876';
    left: 38px;
    @include theme($themes){
        background-color: variable('primaryBackground');
        color: variable('secondaryText') !important;
    }
}
#button-3 .checkbox:checked ~ .layer{
    @include theme($themes){
        background-color: darken(variable('pageBackground'), 7%) !important;
    }
}