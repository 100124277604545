.tempo-toaster{
   // color: white !important;
  //  background: $boxBackground !important;

    @include theme($themes){
        background-color: variable('boxBackground') !important;
        color: variable('pageText') !important;
    }

    padding: 10px 25px !important;
    min-width: 200px;
    border-radius: 6px !important;
    font-weight: bold;

    .go318386747{
        margin-left: 15px;
    }

    .go2344853693{
        transition: .2s ease-in-out;

        @include theme($themes){
            background-color: variable('primaryText');
        }

        &::after{
            transition: .2s ease-in-out;
            @include theme($themes){
                border-color: variable('secondaryText');
            }
        }
    }
}