.mobile-navigation{
    display: none;
    
    @include until(1023px){
        display: block;
    }
    
    .nav {
        width: 100vw;
        padding: 15px 30px;
        backdrop-filter: blur(18px) !important;
        position: relative;
        z-index: 68;
        box-shadow: $shadow;
        top: 0;
        left: 0;
        position: fixed;
    
        @include theme($themes) {
            background: rgba(variable('boxBackground'), .7) !important;
            color: variable('pageText') !important;
        }
    
        .tempo-logo {
            font-size: 28px;
            text-transform: uppercase;
            font-weight: 900;
            position: relative;
            text-align: center;
            @include theme($themes) {
                color: variable('primaryText') !important;
            }
        }
        
        #nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
            width: 60px;
            height: 45px;
            position: relative;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
            cursor: pointer;
            transform: scale(0.45);
            margin-left: -20px;
        }
    
        #nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
            display: block;
            position: absolute;
            height: 9px;
            width: 100%;
            background: white;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }
    
        #nav-icon2 {
        }
    
        #nav-icon2 span {
            display: block;
            position: absolute;
            height: 9px;
            width: 50%;
            background: white;
            opacity: 1;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }
    
        #nav-icon2 span:nth-child(even) {
            left: 50%;
            border-radius: 0 9px 9px 0;
        }
    
        #nav-icon2 span:nth-child(odd) {
            left:0px;
            border-radius: 9px 0 0 9px;
        }
    
        #nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
            top: 0px;
        }
    
        #nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
            top: 18px;
        }
    
        #nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
            top: 36px;
        }
    
        #nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    
        #nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    
        #nav-icon2.open span:nth-child(1) {
            left: 5px;
            top: 7px;
        }
    
        #nav-icon2.open span:nth-child(2) {
            left: calc(50% - 5px);
            top: 7px;
        }
    
        #nav-icon2.open span:nth-child(3) {
            left: -50%;
            opacity: 0;
        }
    
        #nav-icon2.open span:nth-child(4) {
            left: 100%;
            opacity: 0;
        }
    
        #nav-icon2.open span:nth-child(5) {
            left: 5px;
            top: 29px;
        }
    
        #nav-icon2.open span:nth-child(6) {
            left: calc(50% - 5px);
            top: 29px;
        }
    
    }
    
    .user-icon{
        width: 42px;
        height: 42px;
        border-radius: 100%;
        float: right;
    }
    
    .full-nav{
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 67;
        
        backdrop-filter: blur(18px) !important;
        padding: 90px 15px 15px 15px;
    
        transform: translateX(-100%);
    
        transition: all .5s;
        
        @include theme($themes) {
            background: rgba(variable('boxBackground'), .7) !important;
            color: variable('pageText') !important;
        }
        
        .mobile-link{
            padding: 10px 15px;
            border-radius: 6px;
            width: 100%;
            display: block;
            font-weight: bold;
            color: white;
            transition: .2s ease-in-out;
            
            &:hover{
                background: rgba(white, .08);
            }
        }
    }
}
.desktop-navigation{
    display: block;
    @include until(1023px){
        display: none;
    }
}
.navbar{
    @include theme($themes){
        background: variable('boxBackground');
        color: variable('pageText') !important;
    }

    &.is-fixed-top{
        @include theme($themes){
            background: rgba(variable('boxBackground'), .7)!important;
            color: variable('pageText') !important;
        }
        backdrop-filter: blur(18px) !important;
        box-shadow: $shadow;
    }

    padding: 10px 30px;
    position: sticky;
    font-family: $poppins !important;
    z-index: 20 !important;
    box-shadow: -5px -1px 2px rgba(0,0,0,0.04), 
        -5px  -2px 4px rgba(0,0,0,0.04), 
        -5px -4px 8px rgba(0,0,0,0.04), 
        -5px  -8px 16px rgba(0,0,0,0.04);

    .navbar-item.logo{
        margin-right: 10px;
        font-size: 24px;
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-top: 8px;

        &:hover{
             &:before{
                width: 100% !important;
             }
        } 

        .text{
            font-size: 28px;
			text-transform: uppercase;
			font-weight: 900;
			position: relative;
            transition: .2s ease-in-out; 

            @include theme($themes){
                color: variable('primaryText') !important;
            }
            &:before{
                content: attr(data-text);
                position: absolute;
                color: #fff;
                width: 0;
                overflow: hidden;
                transition: 0.6s;
            }
        }

        &:before{
            content: "";
			width: 0%;
			position: absolute;
            @include theme($themes){
                background: variable('primaryBackground') !important;
            }
            height: 57px;
			right: 0;
			top: 0;
			transition: 0.6s;
            margin-bottom: 0;
            border-radius: 4px;
        }

        &:hover .text::before{
            width: 100%;
        }
    

        .navbar-item{
            color: rgba(white, .9) !important;
        }

        img{
            max-height: 52px;
            height: 52px;
            width: 52px;
            border-radius: 50%;
            margin-bottom: -5px;
        }

        .tempo-logo{
            background: url("/images/logo.webp") !important; 
            background-size: 52px 52px !important;
            height: 52px;
            width: 52px;
            border-radius: 50%;
            transition: background .2s ease-in-out;

            @include theme($themes){
                background: variable('logo') !important;
                background-size: 52px 52px !important;
            }
        }

    }

    .text-link{
        background-color: transparent !important;
        border: none !important;
        font-size: 16px;
        transition: .2s ease-in-out;
        line-height: 3.5;
        cursor: pointer !important;

        @include theme($themes){
            color: variable('pageText') !important;
        }

        &:hover{
            background-color: transparent;

            @include theme($themes){
                color: variable('primaryText') !important;
            }
        }
    }

    .premium-upgrade{
        padding: 5px;
        background-color: rgba($premium, .08) !important;
        border: 1px solid rgba($premium, .8);
        border-radius: 12px;
        margin-top: 6px;
        margin-right: 15px;
        box-shadow: $shadow;
        transition: .2s ease-in-out;
        display: none;

        .material-icons-round{
            font-size: 20px;
            margin-bottom: -15px;
            color: rgba($premium, .9);
            transition: .2s ease-in-out;
        }

        p{
            margin-top: 3px;
            color: rgba($premium, .9);
            transition: .2s ease-in-out;
        }

        &:hover{
            border-color: $premium;
            
            .material-icons-round,
            p{
                color: $premium;
            }
        }
    }

    .login-button{
        margin-top: 0.5rem;
        box-shadow: none !important;
        outline: 0 !important;

        &.is-loading{
            color: $boxBackground !important;
        }
    }

    .is-help-section{

        .help-link{
            cursor: context-menu !important;
        }

        .navbar-dropdown{
            margin-top: -10px;
            @include theme($themes){
                background-color: variable('pageBackground') !important;
            }
            box-shadow: 0 8px 8px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
            border: 1px solid rgba(255, 255, 255, .03) !important;
            border-radius: 6px !important;
            padding: 5px;
            max-width: 175px !important;

            opacity: 0 !important;
            -webkit-transform-origin: 50% 0 !important;
            -ms-transform-origin: 50% 0 !important;
            transform-origin: 50% 0 !important;
            -webkit-transform: scale(0.75) translateY(-21px) !important;
            -ms-transform: scale(0.75) translateY(-21px) !important;
            transform: scale(0.75) translateY(-21px) !important;
            -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out !important;
            transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out, height .3s ease !important;
            visibility: collapse !important;
            overflow: hidden !important;

            .navbar-item{
                background-color: transparent !important;
                transition: .2s ease-in-out;
                border-radius: 6px;
                @include theme($themes){
                    color: variable('pageText') !important;
                }
                
                cursor: pointer;

                &:hover{
                // background-color: rgba(255, 255, 255, .04) !important;
                    @include theme($themes){
                        color: variable('pageText') !important;
                        background-color: rgba(variable('boxBackgroundSecondary'), .04) !important;
                    }
                }
            }

        }

        &:hover{
            background-color: transparent !important;

            .navbar-dropdown{
                -webkit-transform: scale(1) translateY(0) !important;
                -ms-transform: scale(1) translateY(0) !important;
                transform: scale(1) translateY(0) !important;
                opacity: 1 !important;
                visibility: visible !important;
            }

            .text-link{
                @include theme($themes){
                    color: variable('primaryText') !important;
                }
            }
        }
    }

}

.box.navigation{
    background-color: $boxBackground !important;
    color: white;
    padding: 0;

    .navbar{
        border-radius: 6px;
        border: none !important;
    }
}


.left-to-right-enter {
    transform: translateX(-100%) !important;
}

.left-to-right-enter-active {
    transform: translateX(0) !important;
}

.left-to-right-enter-done {
    transform: translateX(0) !important;
}

//.left-to-right-exit {
//    transform: translateX(-100%);
//}
//.left-to-right-exit-active {
//    transform: translateX(100%);
//    transition:all 1s ease;
//}
