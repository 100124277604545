@font-face {
    font-family: Proxima-Nova;
    font-weight: normal;
    src: url(./proxima-nova.otf);
}

@font-face {
    font-family: Proxima-Nova;
    font-weight: bold;
    src: url(./proxima-nova-bold.otf);
}

@import url("https://p.typekit.net/p.css?s=1&k=bnd6iyb&ht=tk&f=25136&a=56372144&app=typekit&e=css");

@font-face {
font-family:"proxima-nova";
src:url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/23e139/00000000000000007735e605/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;
}

.tk-proxima-nova { font-family: "proxima-nova",sans-serif; }