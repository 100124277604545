.emoji-pop-wrapper{
    position: relative;
    display: inline-block;

    .emoji-pop-button{
        cursor: pointer;

        .is-16{
            width: 26px;
            filter: grayscale(100%);
            transition: .2s cubic-bezier(0.36, 1.68, 0.46, 1);
        }

        &:hover, &.active{
            .is-16{
                filter: none !important;
                transform: scale(1.1);
            }
        }
    }

    .emoji-picker {
        position: absolute;
        z-index: 3;
        left: 30px;
        top: 0;

        .emoji-picker-sidebar {
            max-height: 388px !important;
            margin-top: 12px !important;
        }

        .highlighted-emoji-container {
            margin-top: 1px;
        }

        .emoji-list {
            padding-top: 60px;
        }
    }
}