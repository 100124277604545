.number-control-input-container{
    position: relative;

    &.light-bg{
        .nci{
            @include theme($themes){
                border: 1px solid rgba(variable('boxBackgroundSecondary'), .12) !important;
            }
            background-color: transparent !important;
           // background-color: rgba(255, 255, 255, .03) !important;
            box-shadow: none !important;
            width: 50px !important;
            text-align: center;
        }
    }

    .number-control-input-label{
        position: absolute;
        top: 0;
        right: 15px;
        font-weight: bold;
        color: rgba(white, .7);
        font-size: 14px;
        text-transform: uppercase;
        line-height: 45px;
    }

    .nci{
        padding: 21px 15px !important;
    }
}

.number-control{
    @include theme($themes){
        background-color: variable('pageBackground') !important;
        box-shadow: variable('itemShadow') !important;
    }
  //  background-color: $pageBackground;
   // box-shadow: $shadow;
    padding: 3px 5px;
    border-radius: 6px;
    min-height: 44px;
    max-height: 44px;

    &.light-bg{
        @include theme($themes){
            background-color: rgba(variable('boxBackgroundSecondary'), .06) !important;
        }
        box-shadow: none !important;
    }

    .control-option{
        padding: 2px 7px;
        padding-bottom: 0px;
        margin-bottom: -18px;
        border-radius: 4px;
        margin-top: 2px;
        transition: .2s ease-in-out;
        display: inline-block;
        cursor: pointer;

        &.disabled{
            @include theme($themes){
                color: rgba(variable('pageText'), .5) !important;
            }
            &:hover{
                cursor: not-allowed !important;
                background-color: transparent !important;
            }
        }
        
        .material-icons-round{
            padding: 0 !important;
            margin: 0 !important;
            margin-bottom: -5px !important;
            font-size: 21px;
            line-height: 1.5 !important;
        }
        
        &:hover{
            @include theme($themes){
                background-color: variable('primaryBackground');
                color: variable('secondaryText');
            }
        }
    }

    .button-spacer{
        height: 24px;
        width: 1px;
        margin: 2px 5px 0px 5px;
        background-color: rgba(255, 255, 255, .04);
    }
}