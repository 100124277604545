.music-player{

    .music-player-queue{
        background-color: $boxBackground;
        box-shadow: $shadow;
        width: 100%;
        margin: 0 auto !important;
        padding: 15px;
        border-radius: 6px;
        min-height: 1050px;
        overflow: hidden !important;

        .relative{
            position: relative;
            width: 100%;
        }
        .search-container{
            padding: 5px;
            transition: background-color .2s ease-in-out;
            background-color: transparent;
            border-radius: 10px;
            width:  100%;
            z-index: 55;
            margin-bottom: -80px;
            position: absolute;

            .search-results{
                visibility: hidden;
                opacity: 0;
                height: 0;
                transition: .2s ease-in-out;
                margin-top: -7px;
                padding: 0 5px 5px 5px;
                max-height: 350px;
                overflow-y: auto;

                .service-button{
                    padding: 2px 10px;
                    border-radius: 4px;
                   // background-color: rgba(white, .02);
                    transition: .2s ease-in-out;

                    color: rgba(white, .7);
                    cursor: pointer;

                    &:hover{
                        background-color: rgba($boxBackground, .3);
                        color: rgba(white, .9);
                    }

                    &.active{
                        background-color: $boxBackground;
                        color: white;
                    }
                }

                .search-query{
                    font-weight: bold;
                    color: rgba(white, .6);
                    text-transform: uppercase;
                    margin-left: 5px; 
                    .search{
                        color: white !important;
                    }
                }

                .search-result-list{
                    list-style-type: none;
                    margin-top: -19px;

                    .search-result-item{
                        padding: 7px 10px;
                        transition: .2s ease-in-out;
                        border-radius: 6px;
                        cursor: pointer;

                        &.focused{
                            background-color: darken($boxBackground, 2%);
                            // box-shadow: $shadow;
                        }

                        font-weight: bold;

                        .spacer{
                            padding: 0 5px;
                        }
                        
                        .search-result-artist{
                            color: rgba(white, .8);
                            font-weight: 400 !important;
                        }
                    }
                }
            }

            &.active{
                background-color: darken($pageBackground, 2%);

                .search-results{
                    visibility: visible;
                    opacity: 1;
                    height: auto !important;
                }

                .input{
                    border-radius: 5px !important;
                    background-color: darken($boxBackground, 2%);
                }

                .icon.is-left{
                    .material-symbols-rounded{
                        color: $themePurple;
                    }
                }
            }

            .icon.is-left{
                left: 10px;
                top: 5px;

                .material-symbols-rounded{
                    font-size: 38px;
                    margin-top: 10px;
                    margin-left: 15px;
                    color: rgba(white, .6);
                    transition: .2s ease-in-out;
                }
            }

            .input{
                border-radius: 15px;
                padding: 20px;
                font-size: 24px;
                background-color: $pageBackground;
                border: none;
                font-weight: bold;
                text-indent: 50px;
                transition: .2s ease-in-out;
                outline: 0;
                box-shadow: none;
                color: white;

                &::placeholder{
                    color: rgba(white, .6);
                }
            }

        }

        hr {
            border:none;
            height: 20px;
            width: 130%;
            margin-left: -10% !important;
            height: 50px;
            margin-top: 0;
            //border-bottom: 1px solid rgba(black, .08);
            box-shadow: 0 20px 20px -20px $pageBackground;
            margin: -50px auto 10px;
            background-color: transparent; 
            margin-bottom: 20px !important;
          }

        .queue{
            padding: 75px 10px 15px 10px;

            .queue-title{
                font-weight: bold;
                color: rgba(white, .6);
                text-transform: uppercase;
                margin-bottom: 5px;
                margin-top: 10px;
            }

            .clear-queue{
                background-color: $pageBackground !important;
               // box-shadow: $shadow !important;
                font-weight: bold;
                padding: 0 10px;
                border-radius: 6px;

                &.disabled{
                    opacity: 0.6;

                    &:hover{
                        color: white !important;
                        cursor: default;
                    }
                }

                &:hover{
                    color: $themeRed !important;
                }
            }
            
            .queue-list{
                overflow-y: auto;
                max-height: 850px;

                .empty-queue{
                    padding: 75px;

                    .disk{
                        width: 175px;
                        height: 175px;
                        margin: 0 auto;
                    }

                    .empty-queue-text{
                        font-size: 38px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }

                    .empty-queue-subtext{
                        font-size: 18px;
                        color: rgba(white, .8);
                    }
                }

                .queue-playlist{
                    border-radius: 6px;
                    background-color: $pageBackground;
                    padding: 10px 10px 5px 10px;

                    .playlist-meta{
                        margin-bottom: 10px;

                        .playlist-art{
                            width: 32px;
                            height: 32px;
                            border-radius: 4px;
                            padding-bottom: 0;
                        }

                        .playlist-name{
                            font-weight: bold;
                            font-size: 14px;
                            color: white;
                            text-transform: uppercase;
                        }

                        .playlist-creator{
                            font-size: 12px;
                            color: rgba(white, .6);
                            margin-top: -7px;
                        }

                        .song-count{
                            padding: 3px 10px;
                            background-color: $boxBackground;
                            color: white;
                            border-radius: 6px;
                        }
                    }

                
                }

                .queue-item{
                    background-color: $pageBackground;
                    border-radius: 6px;
                    padding: 10px 10px;
                    margin-bottom: 5px;
                    transition: .2s ease-in-out;
                    width: 100%;
                    display: inline-block;

                    &.alt{
                        background-color: $boxBackground !important;
                    }

                    .queue-dropdown{
                       // position: relative;
                        display: inline-block;
                        z-index: 999;
                        margin-right: 10px;

                        .queue-dropdown-button{
                            height: 32px;
                            width: 32px;
                            border-radius: 4px;
                            background-color: transparent;
                            transition: .2s ease-in-out;
                            text-align: center;
                            cursor: pointer;

                            .material-symbols-rounded{
                                line-height: 32px;
                            }

                            &:hover{
                                @include theme($themes){
                                    color: variable('primaryText');
                                }
                            }
                        }

                        &.is-active{
                            .queue-dropdown-content{
                                display: block !important;
                            }
                            .queue-dropdown-button{
                                background-color: $boxBackground !important;
                            }
                        }

                        .queue-dropdown-content {
                            display: none;
                            position: absolute;
                            width: 175px;
                            z-index: 1000;
                            margin-left: -142px;
                            border-radius: 4px;
                            box-shadow: $shadow;
                            background-color: darken($pageBackground, 1%);
                            margin-top: 2px;
                            box-shadow: $shadow;
                            padding: 5px;

                            .queue-dropdown-link{
                                background-color: transparent;
                                transition: .2s ease-in-out;

                                &:hover{
                                    cursor: pointer;
                                    background-color: $boxBackground;
                                    color: white !important;
                                    .link-text{
                                        color: white;
                                    }
                                    .material-symbols-rounded{
                                        color: white;
                                    }
                                }

                                padding: 1px 10px;
                                border-radius: 4px;
                                height: 32px;

                                .column{
                                    padding-bottom: 10px;
                                }
                                
                                .link-text{
                                    font-weight: bold;
                                    font-size: 14px;
                                    line-height: 30px;
                                    color: rgba(white, .7);
                                    transition: .2s ease-in-out;
                                }

                                .material-symbols-rounded{
                                    line-height: 30px;
                                    font-size: 18px;
                                    transition: .2s ease-in-out;
                                    color: rgba(white, .7);
                                }
                            }
                        }
                       
                    }

                    &:hover{
                        .drag-indicator{
                            color: rgba(white, .5);
                        }
                    }

                    &:active{
                        // box-shadow: $shadow;
                        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
                        .drag-indicator{
                            color: white;
                        }
                    }

                    .drag-indicator{
                        color: rgba(white, .3);
                        margin-right: -7px;
                        transition: .2s ease-in-out;

                        .material-symbols-rounded{
                            margin-top: 5px;
                        }
                    }

                    .item-icon{
                        border-radius: 4px;
                        width: 52px;
                        height: 52px;
                        margin-bottom: -7px;
                    }

                    .item-name{
                        font-weight: bold;
                        font-size: 18px;
                    }

                    .item-requester{
                        font-size: 14px;
                        color: rgba(white, .7);
                        margin-top: -3px;
                    }
                }
            }
        }
    }

    .queue-player{
        width: 100%;
        background: $boxBackground;
        height: 84px;
        border-radius: 12px;
        position: relative;
        background-position: center center !important; 
        overflow: hidden !important;
        width: 276px;
    
        transition: height .2s ease-in-out;

        &.empty{
            .musicplayer_backdrop{
                visibility: hidden;
            }
        }

        .seekbar{
            width: 100%;
            height: 7px;
            border-radius: 7px;
            background-color: rgba(white, .08);
            backdrop-filter: blur(8px);
            display: inline-block !important;
            margin-left: 7px;
            position: relative;

            &.empty{
                &::after{
                    display: none !important;
                }
            }

            &::after{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 175px;
                height: 7px;
                background: rgba(white, .7);
                backdrop-filter: blur(8px);
                border-radius: 7px;
            }
        }
    
        &.large{
            height: 345px;
    
            .musicplayer_backdrop{
                height: 345px;
            }
    
            .musicplayer_content{
                padding: 15px;
    
                .musicplayer_art{
                    width: 245px !important;
                    height: 245px !important; 
                }
    
                .musicplayer_songname{
                    margin-top: -12px;
                }
            }
        }
    
        .musicplayer_backdrop{
            position: absolute;
            width: 100%;
            height: 84px;
            background-color: rgba(#111, .7);
            backdrop-filter: blur(12px);
            border-radius: 0px;
            z-index: 10;
        }
    
        .musicplayer_content{
            position: absolute;
            z-index: 11;
            padding: 10px;
            margin-top: 3px;
    
            .columns{
                width: 240px;
            }
    
            .musicplayer_art{
                width: 64px;
                height: 64px;
                border-radius: 8px;
                background-size: contain !important; 
                box-shadow: $shadow !important;
                transition: width .2s ease-in-out,
                            height .2s ease-in-out;
                margin-bottom: -10px;
            }
    
            .musicplayer_songname {
                position: relative !important;
                width: 180px;
                height: 35px;
                padding: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-top: 0;
                padding-bottom: 0;
                padding-right: 10px;
                padding-left: 10px;
                font-size: 18px;
    
                &.scroll{
                    .song-name{
                        animation: scroll 12s linear 4s infinite !important;
                    }
                }
            
                .song-name {
                    position: absolute;
                    width: auto;
                    overflow: hidden !important;
                    font-weight: bold;
                }
    
                @-webkit-keyframes scroll {
                    from {
                        transform: translate(0, 0);
                    }
                
                    to {
                        transform: translate(-500px, 0);
                    }
                }
                
                @keyframes scroll {
                    from {
                        transform: translate(0, 0);
                    }
                
                    to {
                        transform: translate(-500px, 0);
                    }
                }
            }
    
            .musicplayer_artistname{
                color: rgba(white, .6);
                padding-left: 10px;
                padding-right: 10px;
                margin-top: -15px;
                //width: 180px;
            }
        }
    }
    .sidebar_musicplayer{
        width: 100%;
        background-color: red;
        min-height: 320px;
        position: relative;
        border-radius: 10px;
        background-size: cover !important;
        cursor: pointer;
    
        .expanded{
            position: fixed !important;
            left: 0 !important;
        }
    
        .musicplayer_backdrop{
            position: absolute;
            width: 100%;
            min-height: 320px;
            background-color: rgba(#111, .5);
            backdrop-filter: blur(12px);
            border-radius: 8px;
            z-index: 10;
        }
    
        .musicplayer_content{
            position: absolute;
            z-index: 11;
            padding: 15px;
    
            .musicplayer_art{
                width: 240px;
                height: 240px;
                border-radius: 6px;
                margin-bottom: 10px;
                background-size: contain !important; 
                box-shadow: $shadow !important;
            }
    
            .musicplayer_songname {
                position: relative !important;
                width: 240px;
                height: 35px;
                padding: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-top: 0;
                padding-bottom: 0;
                padding-right: 8px;
                padding-left: 5px;
    
                &.scroll{
                    .song-name{
                        animation: scroll 12s linear 4s infinite !important;
                    }
                }
            
                .song-name {
                    position: absolute;
                    width: auto;
                    overflow: hidden !important;
                    font-weight: bold;
                }
    
                @-webkit-keyframes scroll {
                    from {
                        transform: translate(0, 0);
                    }
                
                    to {
                        transform: translate(-500px, 0);
                    }
                }
                
                @keyframes scroll {
                    from {
                        transform: translate(0, 0);
                    }
                
                    to {
                        transform: translate(-500px, 0);
                    }
                }
            }
    
            .musicplayer_artistname{
                color: rgba(white, .6);
                padding-left: 5px;
                padding-right: 8px;
                margin-top: -15px;
            }
    
            .loader {
                border: 16px solid #21ac62;
                border-radius: 50%;
                width: 120px;
                height: 120px;
                animation: fill linear 3s;
            }
            
            @keyframes fill {
                0% {
                    clip-path: polygon(50% 0%, 50% 50%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
                }
            
                12.5% {
                    clip-path: polygon(50% 0%, 50% 50%, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
                }
            
                37.5% {
                    clip-path: polygon(50% 0%, 50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 0%);
                }
            
                62.5% {
                    clip-path: polygon(50% 0%, 50% 50%, 0% 100%, 0% 100%, 0% 100%, 100% 100%, 100% 0%);
                }
            
                87.5% {
                    clip-path: polygon(50% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%);
                }
            
                100% {
                    clip-path: polygon(50% 0%, 50% 50%, 50% 0%, 0% 0%, 0% 100%, 100% 100%, 100% 0%);
                }
            }
    
            .play-button{
                font-size: 34px;
    
                &:hover{
                    cursor: pointer;
                }
            }
    
            .previous-button{
                font-size: 28px;
    
                &:hover {
                    cursor: pointer;
                }
            }
    
            .next-button{
                font-size: 28px;
    
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

@-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
  