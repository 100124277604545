.tempo-logo{
    &.large{
        width: 75%;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

.big-tempoline{
    font-size: 48px;
    font-weight: bold;
    transition: .2s ease-in-out;

    @include theme($themes){
        color: variable('pageText');
    }

    line-height: 1.1;
    
    span{
        transition: .2s ease-in-out;
        @include theme($themes){
            color: variable('primaryText');
        }
    }
}

.small-tagline{
    transition: .2s ease-in-out;
    @include theme($themes){
        color: rgba(variable('pageText'), .6);
    }
    font-size: 16px;
    margin: 15px 0;
}

.svg-img{
    width: 100%;
}

.feature-title{
    font-weight: bold;
    font-size: 42px;
    @include theme($themes){
        color: variable('pageText');
    }
}

.feature-description{
    @include theme($themes){
        color: rgba(variable('pageText'), .6);
    }
}

.get-started{
    font-weight: bold;
    margin-top: 50px;

    &.feature-button{
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .material-symbols-rounded{
        position: relative;
        transform: translateX(0px);
        display: inline-block;
        margin-left: 5px;
        transition: .2s ease-in-out;
    }

    &:hover{
        .material-symbols-rounded{
            transform: translateX(7px) !important;
        }
    }
}

.bubble-container{
    width: 100%;
    
    height: 600px;
    // background-color: rgba(white, .01) !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    
    @include until(1023px){
        display: none;
    }

    .bubble-main{
        width: 256px;
        height: 256px;
        top: 10em;
        left: 12.5em;
        z-index: 10;
        position: absolute;
        border-radius: 100%;

        filter: drop-shadow(0px 5px 10px rgba(11, 11, 11 ,0.3));
    }

    .bubble{
        width: 72px;
        height: 72px;
        border-radius: 100%;
        position: absolute;
        z-index: 9999;

        &.tempo-two{
            top: 12%;
            left: 15%;
            width: 95px;
            height: 95px;

            animation: floatLogo 3s ease-in-out infinite;
            filter: drop-shadow(0px 5px 10px rgba($themeYellow,0.15));
        }

        &.tempo-three{
            width: 63px;
            height: 63px;
            right: 0%;
            top: 22%;

            animation: floatLogo 3.4s ease-in-out infinite;
            filter: drop-shadow(0px 5px 10px rgba($themeGreen,0.3));
        }

        &.tempo-four{
            width: 74px;
            height: 74px;
            bottom: 27%;
            left: 10%;

            animation: floatLogo 3.2s ease-in-out infinite;
            filter: drop-shadow(0px 5px 10px rgba($themeBlue,0.3));
        }

        &.tempo-five{
            bottom: 27%;
            left: 85%;
            width: 55px;
            height: 55px;

            animation: floatLogo 3.6s ease-in-out infinite;
            filter: drop-shadow(0px 5px 10px rgba($themeAqua,0.3));
        }

        &.tempo-six{
            bottom: 0%;
            left: 55%;
            width: 66px;
            height: 66px;

            animation: floatLogo 3.8s ease-in-out infinite;
            filter: drop-shadow(0px 5px 10px rgba($themeRed,0.3));
        }
    }
}


@keyframes floatLogo {
    0% {  transform: translateY(0px);}
    50% {  transform: translateY(-10px);}
    100% {  transform: translateY(0px);}
}

.parallax-wrap {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .parallax-wrap h2 {
    position: relative;
    font-size: 100px;
    color: white;
    z-index: 2;
    text-align: center;
  }
  .parallax-wrap span {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 100%;
  }
  .parallax-wrap span:nth-child(1) {
    top: 25%;
    left: 25%;
    background: blue;
    z-index: 3;
  }
  .parallax-wrap span:nth-child(2) {
    top: 60%;
    left: 80%;
    background: yellow;
    z-index: 3;
  }
  .parallax-wrap span:nth-child(3) {
    top: 40%;
    left: 60%;
    background: green;
    z-index: 3;
  }
  .parallax-wrap span:nth-child(4) {
    top: 70%;
    left: 40%;
    background: red;
    z-index: 3;
  }
  .parallax-wrap span:nth-child(5) {
    top: 40%;
    left: 30%;
    background: purple;
    z-index: 3;
  }

.home-header{
    margin-bottom: -75px;
    
    .mobile-image{
        display: none;
    }
    
    @include until(1023px){
        flex-direction: column-reverse;
        display: flex;
        text-align: center !important;
        
        .small-tagline{
            width: 90%;
            margin: 15px auto;
        }
        
        .mobile-image{
            display: block;
        }
    }
}

.image-feature{
    margin-bottom: 125px;
    
    @include until(1023px){
        text-align: center;
        
        .feature-description{
            width: 90%;
            margin: 10px auto;
        }
    }

}

.coming-soon{
    padding: 5px 10px;
    border-radius: 50px;
    display: inline-block;
    font-size: 12px;
    transition: .2s ease-in-out;
    cursor: default;

    @include theme($themes){
        background: rgba(variable('primaryBackground'), .3);
        color: variable('pageText');
    }

    &:hover{
        @include theme($themes){
            background: rgba(variable('primaryBackground'), .5);
            color: variable('pageText');
        }
    }
}

.edge-box{
    width: 100%;
    background-color: $boxBackground;
    padding: 25px 50px;
    font-weight: bold;
    font-size: 28px;
    color: white;
    margin: 150px 0;
    
    @include until(1023px){
        margin: 25px 0;
        text-align: center;
        padding: 45px 50px 25px 50px;
        
        .feature-button{
            width: 100%;
        }
    }

    @include theme($themes){
        background: variable('boxBackground');
        color: variable('pageText');
        box-shadow: variable('shadow');
    }

    b{
        @include theme($themes){
            color: variable('primaryText');
        }
    }
}

.is-premium{

    .premium-title{
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 50px;
        width: 100vw;

        @include until(1023px){
            margin-bottom: 15px;
        }
        
        @include theme($themes){
            color: variable('pageText');
        }

        b{
            color: $premium;
        }
    }

    .premium-info-box{
        //background-color: $boxBackground;
        border-radius: 6px;
        padding: 15px;
        //box-shadow: $shadow;
        width: 350px;
        
        margin: 0 auto !important;
    
        @include theme($themes){
            background: variable('boxBackground');
            color: variable('pageText');
            box-shadow: variable('shadow');
        }

        transform: perspective(1px) scale(1.00);
        transition: .5s cubic-bezier(0.36, 1.68, 0.46, 1);

        &:hover{
            transform: perspective(1px) scale(1.05);
            // transform: translateY(-5px);
        }

        .icon-box{
            width: 42px;
            height: 42px;
            border-radius: 6px;
            background-color: $pageBackground;
            box-shadow: $shadow;
            text-align: center;

            .material-symbols-rounded{
                font-size: 28px;
                line-height: 40px;
                color: $premium;
            }
        }

        .info-title{
            font-weight: bold;

            @include theme($themes){
                color: variable('pageText');
            }
        }

        .info-description{
            padding: 0 5px;
            margin-top: -10px;

            @include theme($themes){
                color: rgba(variable('pageText'), .5);
            }
        }
    }

}

.premium-button{
    background-color: $boxBackground !important;
    border: none;
    margin-top: -10px;
    margin-bottom: 75px;
    color: $premium;

    &:hover{
        background-color: lighten($boxBackground, 2%) !important;
        color: $premium;
    }
}

.star-container{
    position: relative;

    img.left{
        position: absolute;
        left: -68px;
        top: -68px;
        transform: rotate(-32deg);
        z-index: 9;
    }

    img.right{
        position: absolute;
        right: -68px;
        top: -68px;
        transform: rotate(32deg);
        z-index: 9;
    }

    .premium-title{
        position: relative;
        z-index: 10;
    }
}