:root {
    --head-color: blue;
    --background: gray;
    --fillColor: purple;
    --fillSecondry: orange;
    --changeColorPosition: 10%;
    --width: 100%;
    --headShadowColor: gold;
    --headShadowSize: -6px;

}

input[type="range"] {
    @include theme($themes) {
        --fillColorTheme: variable('primaryBackground');
        --fillColorThemeLight: lighten(variable('primaryBackground'), 3%);
    }
    -webkit-appearance: none;
    //margin-right: 15px;
    width: 90%;
    margin: 0 auto !important;
    height: 7px;
    background: rgba(#111, .5);
    border-radius: 5px;
    @include theme($themes){
        background-image: linear-gradient(30deg, variable('primaryBackground'), lighten(variable('primaryBackground'), 5%));
    }
    //background-image: linear-gradient(12deg, var(--fillColorTheme), var(--fillColorThemeLight));
    background-size: var(--changeColorPosition) 100%;
    background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: white;
    cursor: ew-resize;
    box-shadow: $shadow;
    transition: 0.3s ease-in-out;
}

input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}