.base-footer{
    width: 100%;
    padding: 50px 50px 50px 50px;
    background-color: $boxBackground;

    .tempo-logo{
        background: url("/images/logo.webp") !important; 
        background-size: 52px 52px !important;
        height: 52px;
        width: 52px;
        border-radius: 50%;
        transition: background .2s ease-in-out;

        @include theme($themes){
            background: variable('logo') !important;
            background-size: 52px 52px !important;
        }
    }

    .tempo{
        font-size: 28px;
        text-transform: uppercase;
        font-weight: 900;
        position: relative;
        transition: .2s ease-in-out; 

        color: white;
    }

    .tempo-subtext{
        color: rgba(white, .4);
        font-size: 14px;
        max-width: 300px;
        margin-bottom: 15px;
    }

    .links-header{
        font-weight: bold;
        text-transform: uppercase;
        color: white;
        font-size: 20px;
        display: block;
        margin-bottom: 5px;
    }

    .link-item{
        color: rgba(white, .6);
        text-transform: uppercase;
        cursor: pointer;
        transition: .2s ease-in-out;

        display: block;

        &:hover{
            color: rgba(white, .9);
        }
    }

    .tempo-copyright{
        font-size: 16px;
        color: rgba(white, .6);
        text-transform: uppercase;
    }
}