.server-list-loader{
    padding: 0;
    padding-right: 15px;
    background-color: $boxBackground;
    box-shadow: $shadow;
    border-radius: 6px;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    margin-bottom: 0px;

    .loader-icon-box{
        width: 100px;
        height: 75px;

        .icon-image{
            margin-left: 15px;
            margin-top: 8px;
        }
    }

    .server-name-loader{
        margin-bottom: 3px;
    }
}

.server-search{
    background-color: lighten($boxBackground, 2%);
    box-shadow: $shadow;
    border-radius: 6px;
    display: inline-block;
    padding: 10px 15px;
    width: 100%;
    margin-bottom: 7px;
    
    .input{
        background-color: transparent;
        box-shadow: none;
        border: none;
        color: white;
        outline: 0;
        font-weight: bold;

        &::placeholder{
            color: rgba(white, .3);
        }
    }

    .material-symbols-rounded{
        color: rgba(white, .5) !important;
    }

}

.server-list-box{
    background-color: $boxBackground;
    box-shadow: $shadow;
    padding: 0;
    padding-right: 15px;
    border-radius: 6px;
    display: inline-block;
    overflow: hidden !important;
    width: 100%;

    &.support{
        .server-info{
            .server-rank{
                color: #009B77 !important;
            }
        }
    }

    .server-avatar{
        position: relative;
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
        overflow: hidden !important;

        .server-avatar-blur{
            content: '';
            background: linear-gradient(to left, $boxBackground, rgba(0,0,0,0) 50%);
            width: 100px;
            height: 75px;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 9;
            backdrop-filter: blur(2px);
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
            transition: .2s ease-in-out;
        }

        .server-avatar-background{
            -webkit-mask-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0));
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px !important;
            margin-bottom: -7px;
            width: 100px;
            height: 75px;
            transition: .2s ease-in-out;
            transform: scale(1.1);
            object-fit: cover;
        }

        .server-bubble{
            position: absolute;
            z-index: 10;
            top: 8px;
            left: 15px;

            .server-bubble-icon{
                width: 60px;
                height: 60px;
                border-radius: 100% !important;
                border: 2px solid white;
            }
        }

        transition: .2s ease-in-out;
    }

    .server-info{
        .server-name{
            font-weight: bold;
            font-size: 24px;
            color: white;
        }
        .server-rank{
            margin-top: -3px;
            color: rgba(white, .6);
            font-size: 14px;

            .material-symbols-rounded{
                font-size: 20px;
            }

            .server-rank-text{
                margin-left: 5px;
            }

            &.owner{
                color: #D65076;
            }

            &.s_admin{
                color:  #98B4D4;
            }

            &.b_admin{
                color: #45B8AC;
            }

        }
        .is-configure-button{
            width: 200px;
        }
    }

    .button{
        transition: background .2s ease-in-out;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        border: none;
        box-shadow: none;
        width: 150px;

        &.is-configure{
            background: rgba(255, 255, 255, .08) !important;
            transition: background .2s ease-in-out;
            &:hover{
                background-color: rgba(255, 255, 255, .06) !important;
            }
        }

        &.is-setup{
            background-color: $pageBackground;
            border: none;
            color: white;
            transition: background .2s ease-in-out;

            &:hover{
                background-color: darken($pageBackground, 2%);
            }
        }
    }

    .support-session-button{
        font-weight: bold;
        background: rgba(255, 255, 255, .08) !important;
        transition: background .2s ease-in-out;
        
        &:hover{
            background-color: rgba(255, 255, 255, .06) !important;
        }

        &.left{
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        &.right{
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }

    &:hover{
        .server-avatar-background{
            transform: scale(1.4);
        }
    }
}

.server-list{
    padding: 50px;
    padding-bottom: 50px;

    .page-title{
        font-weight: bold;
        font-size: 32px;
        text-align: center;
        color: white;
        margin-bottom: 25px;
    }

}