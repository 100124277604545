.channel-selector{
    width: 100%;
    padding: 10px;
    //background-color: $pageBackground;

    @include theme($themes){
        background-color: variable('pageBackground') !important;
        box-shadow: variable('itemShadow') !important;
    }

    //box-shadow: 0 6px 6px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1) !important;
    border-radius: 6px;
    max-height: 297px;
    overflow-y: auto;

    .channel-item{
        padding: 5px 10px;
        border-radius: 4px;
        margin-bottom: 2px;
        transition: .2s ease-in-out;
        cursor: default;

        &.is-disabled{
            cursor: not-allowed !important;
        }

        &:not(.is-selected):not(.is-disabled):hover{
            cursor: pointer;
            @include theme($themes){
                background-color: rgba(variable('boxBackground'), .5) !important;
            }
            //background-color: rgba($boxBackground, .7);

            .channel-name{
                color: white !important;
            }
        }

        &.is-selected{
            @include theme($themes){
                background-color: variable('boxBackground') !important;
            }
            background-color: $boxBackground;
        }

        .material-icons-round{
            @include theme($themes){
                color: rgba(variable('pageText'), .8) !important;
            }
          //  color: rgba(white, .8);
            margin-top: 6px;
            font-size: 28px;

            &.tick{
                @include theme($themes){
                    color: variable('primaryText') !important;
                }
            }
        }

        .channel-name{
            color: rgba(white, .8);
            font-weight: bold;
        }
    }
}