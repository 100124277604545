.color-button{
    position: relative;
    display: inline-block;

    .color-block{
        transition: .2s ease-in-out;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: rgba(white, .08);
        cursor: pointer;
        position: relative;
        transition: all .5s ease-in-out;
        transition-timing-function: cubic-bezier(0.36, 1.68, 0.46, 1) !important;

        &:hover{
            border-radius: 40%;
        }
        &.display{
            border-radius: 40%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.disabled{
            cursor: not-allowed !important;
            border-radius: 50% !important;
        }
    }


    .color-button-picker-cover{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .color-button-picker-popover{
        position: absolute;
        z-index: 2;
        
        background-color: darken($pageBackground, 3%) !important;
        padding: 10px;
        border-radius: 10px;
        // margin-bottom: 20px;

        .color-picker-text-field{
            margin-top: 10px;
            position: relative;
            z-index: 9999;
        }

        &.display{
            border-top-left-radius: 0;
        }
    }

    .block-picker{
        margin-top: 11px;
        margin-left: -63px;
    }
}
