 .animated-checkbox {
     -webkit-user-select: none;
     user-select: none;
     -webkit-tap-highlight-color: transparent;
     cursor: pointer;
 }

 .animated-checkbox span {
     display: inline-block;
     vertical-align: middle;
     transform: translate3d(0, 0, 0);
 }

 .animated-checkbox span:first-child {
     position: relative;
     width: 24px;
     height: 24px;
     border-radius: 50%;
     transform: scale(1.5);
     vertical-align: middle;
     border: 1px solid rgba(255, 255, 255, .4);
     transition: all 0.2s ease;
 }

 .animated-checkbox span:first-child svg {
     position: absolute;
     z-index: 1;
     top: 7px;
     left: 5px;
     fill: none;
     stroke: white;
     stroke-width: 2;
     stroke-linecap: round;
     stroke-linejoin: round;
     stroke-dasharray: 16px;
     stroke-dashoffset: 16px;
     transition: all 0.3s ease;
     transition-delay: 0.1s;
     transform: translate3d(0, 0, 0);
 }

 .animated-checkbox span:first-child:before {
     content: "";
     width: 100%;
     height: 100%;
     background: $purple;
     display: block;
     transform: scale(0);
     opacity: 1;
     border-radius: 50%;
     transition-delay: 0.2s;
 }

 .animated-checkbox span:last-child {
     margin-left: 8px;
 }

 .animated-checkbox span:last-child:after {
     content: "";
     position: absolute;
     top: 8px;
     left: 0;
     height: 1px;
     width: 100%;
     background: #B9B8C3;
     transform-origin: 0 0;
     transform: scaleX(0);
 }

 .animated-checkbox:not(.disabled):hover span:first-child {
     border-color: rgba(255, 255, 255, .8);
 }

 .animated-checkbox.disabled {
     cursor: not-allowed !important;
 }

 .inp-animated-checkbox:checked+.animated-checkbox span:first-child {
    border-color: $themePurple;
    background: $themePurple;
    animation: check 0.6s ease;
    border: 1px solid #FFF;

 }

 .inp-animated-checkbox:checked+.animated-checkbox span:first-child svg {
     stroke-dashoffset: 0;
 }

 .inp-animated-checkbox:checked+.animated-checkbox span:first-child:before {
     transform: scale(2.2);
     opacity: 0;
     transition: all 0.6s ease;
 }

 .inp-animated-checkbox:checked+.animated-checkbox span:last-child {
     color: #B9B8C3;
     transition: all 0.3s ease;
 }

 .inp-animated-checkbox:checked+.animated-checkbox span:last-child:after {
     transform: scaleX(1);
     transition: all 0.3s ease;
 }

 @keyframes check {
     50% {
         transform: scale(2);
     }
 }