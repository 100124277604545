.module{

    .module-title{
        font-size: 28px;
        @include theme($themes){
            color: variable('pageText') !important;
        }
        font-weight: bold;
        font-family: $poppins;
    }

    .module-subtitle{
        @include theme($themes){
            color: rgba(variable('pageText'), .6) !important;
        }
        font-weight: 300;
        margin-bottom: 30px;
    }



}