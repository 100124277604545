.announcement-image-container{
    width: 100%;
    padding: 25px;
    background-color: $pageBackground;
    border-radius: 6px;
    margin-bottom: 25px;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;

    .announcement-image{
        background: rgba(white, .06);
        padding: 40px 30px;
        border-radius: 4px;

        .announcement-user-icon{
            width: 184px;
            border-radius: 50%;
        }

        .announcement-image-line-one{
            font-weight: bold;
            font-size: 28px;
            text-align: center;
            color: white;
        }

        .announcement-image-line-two{
            font-size: 18px;
        }
    }
}

.premium-star{
    margin-top: 5px;
    color: $premium;
    font-size: 27px;
    cursor: pointer;
}