.purchase-card{
     @include theme($themes){
        background-color: variable('boxBackground');
    }
    box-shadow: none;
    border: none;
    border-radius: 6px;
    padding: 20px 15px; 
    width: 300px;
    border: 2px solid transparent;
    transition: .2s ease-in-out;
    cursor: pointer;
    position: relative;

    &:hover{
        border-color: rgba($premium, .4) !important;
    }

    &.active-plan{
        border-color: $premium !important;
    }

    .stars{
        padding: 15px 15px 0 15px;
        text-align: center;

        .material-icons-round{
            color: $premium;
            font-size: 48px;
        }
    }

    .server-count{
        font-size: 28px;
        font-weight: bold;
        color: white;
        text-align: center;
    }

    .server-desc{
        text-align: center;
        font-size: 16px;
        color: rgba(white, .7);
        padding: 0 15px;
    }

    .plan-price{
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: center;
        color: $premium;

        .plan-dollar{
            font-size: 28px;
            font-weight: bold;
        }

        .plan-sub{
            font-size: 16px;
        }
    }

    .tick{
        position: absolute;
        top: 10px;
        right: 10px;
        color: $premium;
        background: $pageBackground;
        padding: 5px;
        border-radius: 4px;
        box-shadow: $shadow;
    }
}

.select-a-plan{
    color: rgba(white, .9);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
}

.premium-title{
    padding: 45px 0;
    font-size: 38px;
    
    span{
        color: $premium;
    }
}

hr.premium{
    border: 0;
    height: 1px;
    margin: 10px 0 !important;
    background: $pageBackground;
    background-image: linear-gradient(to right, lighten($pageBackground, 5%), $boxBackground, lighten($pageBackground, 2%));
}

.total-box{
    padding: 0 10px;

    .price-total{
        font-size: 20px;
        color: $premium;
        font-weight: bold;
    }
}

.premium-info-box{
    //background-color: $boxBackground;
    border-radius: 6px;
    padding: 15px;
    //box-shadow: $shadow;
    width: 350px;
    
    @include theme($themes){
        background: variable('boxBackground');
        color: variable('pageText');
        box-shadow: variable('shadow');
    }
    
    transform: perspective(1px) scale(1.00);
    transition: .5s cubic-bezier(0.36, 1.68, 0.46, 1);
    
    &:hover{
        transform: perspective(1px) scale(1.05);
        // transform: translateY(-5px);
    }
    
    .icon-box{
        width: 42px;
        height: 42px;
        border-radius: 6px;
        background-color: $pageBackground;
        box-shadow: $shadow;
        text-align: center;
        
        .material-symbols-rounded{
            font-size: 28px;
            line-height: 40px;
            color: $premium;
        }
    }
    
    .info-title{
        font-weight: bold;
        
        @include theme($themes){
            color: variable('pageText');
        }
    }
    
    .info-description{
        padding: 0 5px;
        margin-top: -10px;
        
        @include theme($themes){
            color: rgba(variable('pageText'), .5);
        }
    }
}

.perkbox{
    border-radius: 6px;
    
    &.premium-perks{
        border: 1px solid $premium;
        .header{
            text-align: center;
            color: $premium;
        }
    
        .material-icons-round, .item{
            color: $premium;
        }
    }
    
    &.standard{
        border: 1px solid rgba(white, .12);
        
        .header{
            text-align: center;
            color: rgba(white, .9);
        }
        
        .material-icons-round, .item{
            color: rgba(white, .4);
            text-align: center;
            font-weight: bold;
        }
    }
    
    .header{
        padding: 15px 15px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        font-weight: bold;
        text-transform: uppercase;
        color: white;
        font-size: 18px;
    }
    
    .list-items{
        padding: 10px;
        
        .list-item{
            color: white;
            height: 35px;
            line-height: 35px;
            
            .material-icons-round{
                line-height: 35px;
            }
            
            &:not(:last-child){
                margin-bottom: 5px;
            }
        }
    }
}

.tempo-feature-list{

    .tempo-feature-title{
        background-color: lighten($boxBackground, 2%);
        box-shadow: $shadow !important;
        border-radius: 6px;
        padding: 15px 20px;

        color: white;
        font-weight: bold;
        font-size: 20px;
    }

    .tempo-feature{
        padding: 10px 20px;
        border-radius: 6px;
        background-color: $boxBackground;
        color: white;
        margin-top: 5px;

        span{
            margin-top: 5px;
        }

        .premium{
            color: $premium;
        }
    }

}

#checkout-modal,#checkout-modal-a{
    &.stage-one{
        .modal-content{
            max-width: 400px;
        }
    }

    .checkout-stage-one{
        .checkout-title{
            font-weight: bold;
            text-align: center;
            font-size: 28px;
            margin-bottom: 10px;
            color: white !important;
        }

        .chargebee-button{
            width: 100%;
            background-color: $pageBackground;
            border: none;
            box-shadow: $shadow;
            color: white;
            font-weight: bold;
        }

        .gold{
            color: $premium;
        }

        .premium-details{
            padding: 10px 20px !important;
            background-color: $pageBackground;
            border-radius: 4px;
            box-shadow: $shadow;

            img{
                width: 50px;
                border-radius: 100%;
                margin-bottom: -7px;
            }

            .premium-text{
                font-weight: bold;
                font-size: 20px;
                color: white;
            }
            .premium-subtext{
                color: rgba(white, .7);
                text-transform: capitalize;
                margin-top: -5px;
            }
        }

        .coupon{
            .config-title{
                font-weight: bold;
                text-transform: uppercase;
                color: rgba(white, .7);
                margin-bottom: 5px;
            }

            .input{
                background-color: $pageBackground;
                box-shadow: none !important;
                border: none;
                outline: none !important;
                color: white !important;
                padding: 22px 15px !important;
                margin-right: 2px;
                //border: 1px solid rgba($premium, .8);

                &::placeholder{
                    color: rgba(white, .5);
                }
            }

            .is-info{
                background-color: $pageBackground;
                color: $premium;
               // border: 1px solid rgba($premium, .8);
                height: 44px !important;
                transition: .2s ease-in-out;

                &:hover{
                   // border-color: $premium !important;
                    background-color: $premium;
                    color: $pageBackground;
                }
            }
        }

        .applied-coupon{
            background-color: $pageBackground;
            box-shadow: $shadow;
            padding: 5px 10px;
            color: white;
            margin-top: 10px;
            border-radius: 6px;

            .coupon-code{
                font-weight: bold;
                color: $premium;
            }

            .coupon-discount{
                margin-left: 5px;
                font-size: 16px;
                color: rgba(white, .8);
            }
        }
    }
}