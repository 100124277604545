.tempo-select-container{

    .tempo-select__control{
        background-color: $pageBackground;
        border: 1px solid transparent;
        padding: 6px 10px  !important;
        border-radius: 6px;
        transition: .2s ease-in-out;
        box-shadow: 0 6px 6px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);

        &:hover{
            cursor: pointer;
        }

        &:not(.tempo-select__control--is-focused):hover{
            @include theme($themes){
                border-color: rgba(variable('primaryBackground'), .4) !important;
            }
        }

        .tempo-select__placeholder{
            color: white;
            font-weight: bold;
        }

        .tempo-select__indicator-separator{
            display: none !important;
        }
    }

    .tempo-select__control--menu-is-open{
        @include theme($themes){
            border-color: rgba(variable('primaryBackground'), .8) !important;
        }
    }

    .tempo-select__indicator{
        color: transparent !important;
        font-size: 1px !important;
        padding: 0 !important;

        &::before{
            font-weight: 900;
            font-family: 'Material Icons Round';
            content: '\e313';
            color: white;
            font-size: 20px;
            margin-right: -15px;
        }
    }

    .tempo-select__single-value{
        color: white;
        font-weight: bold;
    }

    .tempo-select__menu{
        background-color: darken($boxBackground, 3%);
        border: 1px solid rgba(255, 255, 255, .03) !important;
        border-radius: 6px !important;
        box-shadow: 0 8px 8px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        margin-top: 5px;
        position: absolute;
        z-index: 2;
        padding: 6px;

        .tempo-select__menu-list{
            padding-bottom: 0 !important;
            padding-top: 0 !important;
        }

        .tempo-select__option{
            border-radius: 6px !important;
            cursor: pointer;
            transition: .2s ease-in-out;

            &.tempo-select__option--is-focused, &:active, &:focus{
                background-color: rgba(255, 255, 255, .04) !important; 
            }
            &.tempo-select__option--is-selected{
                background-color: rgba(255, 255, 255, .06) !important; 
            }
            &.tempo-select__option--is-disabled{
                cursor: not-allowed !important;
                opacity: .6;
            }
        }
    }

}