.is-dashboard-module{
    padding: 10px 25px !important;
    cursor: pointer;
    transition: border .2s ease-in-out;
    border: 1px solid transparent;

    min-width: 315px;

    @include theme($themes){
        box-shadow: variable('boxShadow') !important;
    }

    &.is-readonly{
        cursor: not-allowed !important;
    }

    &.is-disabled{
        .module-name{
            opacity: .5 !important; 
             @include theme($themes){
                color: rgba(variable('pageText'), .85) !important;
            }
        }

        .module-description{
            opacity: .5 !important; 
            @include theme($themes){
                color: rgba(variable('pageText'), .7) !important;
            }
        }

        .material-icons-round{
            opacity: .5 !important;
            @include theme($themes){
                color: variable('pageText') !important;
            }
        }

        &:not(.is-readonly):hover{
            .module-name{
                opacity: 1 !important;
                @include theme($themes){
                    color: rgba(variable('pageText'), .85) !important;
                }                
            }
            .module-description{
                opacity: 1 !important;
                @include theme($themes){
                    color: rgba(variable('pageText'), .7) !important;
                }
            }
            .material-icons-round{
                opacity: 1 !important;
            }
        }
    }

    &:not(.is-readonly):hover{
        @include theme($themes){
            border-color: rgba(variable('pageText'), .1) !important;
        }
    }

    .material-icons-round{
        font-size: 38px;
        margin-top: 0;
        line-height: unset !important;
        transition: .2s ease-in-out;

        @include theme($themes){
            color: variable('pageText') !important;
        }
    }

    .module-name{
        font-weight: bold;
        @include theme($themes){
            color: rgba(variable('pageText'), .85) !important;
        }
        font-size: 18px;
        margin-left: 15px;
        transition: .2s ease-in-out;
    }

    .module-description{
        font-size: 14px;
        @include theme($themes){
            color: rgba(variable('pageText'), .7) !important;
        }
        margin-top: -15px;
        margin-bottom: 5px;
        text-align: center;
        min-width: 250px;
        transition: .2s ease-in-out;
    }
}

.support-code-box{
    width: 100%;
    padding: 10px 15px;
    background-color: $pageBackground !important;
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
    border-radius: 4px;

    a{
        @include theme($themes){
            color: variable('primaryText');
        }

        transition: .2s ease-in-out;

        &:hover{
            text-decoration: underline;
        }
    }
}