.file-select-button-no-image,
.file-select-button-uploaded-image{
    width: 175px;
    height: 125px;
    margin-top: 20px;

    &.is-disabled{
        cursor: not-allowed !important;
    }

    @include theme($themes){
        background-color: variable('pageBackground') !important;
        box-shadow: variable('itemShadow') !important;
    }

    &.child{
        @include theme($themes){
            background-color: variable('boxBackground') !important;
        }
        box-shadow: none !important;
    }

    &.square{
        height: 175px !important;
        border-radius: 4px !important;
        margin-top: 17px !important;

        .columns{
            height: 180px !important;
        }
    }

    &.is-44{
        width: 44px !important;
        height: 44px !important;
        margin-top: 11px !important;

        .columns{
            height: 50px !important; 
        }

        .material-icons-round{
            font-size: 24px;
        }
    }

    &.circle{
        border-radius: 100%;
    }
   // background-color: $pageBackground;
    //border: 2px dashed rgba(white, .3);
    border-radius: 6px;
   // box-shadow: 0 6px 6px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1) !important;
}

.file-select-button-no-image{
    &.is-disabled{
        cursor: not-allowed !important;
    }
    cursor: pointer;

    .columns{
        height: 130px !important;
    }

    .material-icons-round{
        @include theme($themes){
            color: rgba(variable('pageText'), .9);
        }
        font-size: 48px;
        transition: .2s ease-in-out;
    }

    &:not(.is-disabled):hover{
        .material-icons-round{
            @include theme($themes){
                color: variable('primaryText') !important;
            }
        }
    }
}

.file-select-button-uploaded-image{
    background-size: cover !important;
    background-position: center !important;
    position: relative;
    margin-top: 8px;

    &.is-disabled{
        cursor: not-allowed !important;
    }

    .control-box{
        position: absolute;
        top: 4px;
        right: 4px;
        background-color: rgba(black, .8);
        border-radius: 6px;
        padding: 5px;

        .columns{
            padding: 0 !important;
            margin-bottom: -15px !important;
        }

        .material-icons-round{
            font-size: 20px !important;
            color: white !important;
            padding: 4px;
            border-radius: 4px;
            margin-bottom: -2px !important;
            transition: .2s ease-in-out;
            cursor: pointer;

            &:hover{
                @include theme($themes){
                    background-color: variable('primaryBackground');
                    color: variable('secondaryText') !important;
                }
            }
        }
    }
}