.search-box{
    background-color: $boxBackground;
    border: 1px solid transparent;
    color: white;
    outline: 0 !important;
    box-shadow: none !important;
    transition: .2s ease-in-out;
    padding: 15px 15px;
    width: 275px;

    &::placeholder{
        color: rgba(white, .4);
    }

    &:hover{
        @include theme($themes){
            border-color: rgba(variable('primaryBackground'), .4) !important;
        }
    }

    &:active, &:focus{
        @include theme($themes){
            border-color: variable('primaryBackground') !important;
        }
    }
}

.new-subscription{
    background-color: $boxBackground !important; 
    padding: 5px 15px;
    color: white;
    border: none !important;
    transition: .2s ease-in-out;

    &:hover{
        @include theme($themes){
            color: variable('primaryBackground') !important;
        }
    }
}

.subscription-list{

    .subscription-item{
        width: 100%;
        background-color: $boxBackground;
        border-radius: 6px;
        padding: 10px 15px;
        margin-bottom: 10px;

        .active-indicator{
            background-color: #2ECC40;
            width: 14px;
            height: 14px;
            border-radius: 100%;
            margin-left: 10px;

            &.red{
                background-color: #FF4136;
            }
        }

        .sub-title{
            font-size: 20px;
            font-weight: bold;
        }

        .sub-info{
            color: rgba(white, .7) !important;
            font-size: 16px;
            margin-top: -5px;
            margin-right: 10px;
        }
    }
}

.sub-button{
    background-color: $pageBackground !important;
    transition: .2s ease-in-out !important;

    &:hover{
        @include theme($themes){
            color: variable('primaryBackground') !important;
        }
    }
}