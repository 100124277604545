.configuration-section{
    padding: 20px 25px;
    margin-bottom: 15px;
    
    @include theme($themes){
        box-shadow: variable('boxShadow') !important;
    }

    &.danger-zone{
        border: 1px solid $themeRed;

        .danger-zone-tag{
            background-color: $pageBackground !important;
            font-size: 12px;
            font-weight: bold;
            color: $themeRed !important;
            padding: 2.5px 5px;
            border-radius: 6px;
            display: inline-block !important;
            margin-top: -15px;
            cursor: help;
        }
    }

    &.is-child-section{
        background-color: $pageBackground;
        padding: 15px 20px !important; 
    }

    .configuration-collapse-toggle{
        cursor: pointer;

        &.disabled{
            .material-icons-round{
                //color: rgba(255, 255, 255, .6) !important;
                @include theme($themes){
                    color: rgba(variable('pageText'), .6) !important;
                }
                cursor: not-allowed; 
            }   
        }

        &:not(.disabled){
            &:hover{
                .material-icons-round{
                    @include theme($themes){
                        color: variable('primaryText');
                    }
                }
            }
        }

        .material-icons-round{
            transition: .2s ease-in-out;
            font-size: 28px;
            margin-bottom: -50px;

            &.open{
                transform: rotate(180deg);
            }
        }
    }

    .configuration-spacer{
        width: 100%;
        height: 5px !important;
    }

    .margin{
        margin-bottom: -15px !important;
    }

    .big-margin{
        margin-bottom: -30px !important;
    }

    .configuration-section-header{
       // margin-bottom: 15px;
        
        .configuration-section-title{
            font-weight: bold;
            @include theme($themes){
                color: variable('pageText') !important;
            }
            font-size: 22px !important;

            &.warning-red{
                color: $themeRed !important;
            }
        }

        .configuration-section-description{
            font-size: 15px;
            margin-top: 0px;
            @include theme($themes){
                color: rgba(variable('pageText'), .6) !important;
            }
        }
    }

    .config-wrapper{
        position: relative;
        padding: 15px;
        .config-block{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(6px);
            z-index: 999;

            border-radius: 6px;
            margin-bottom: 10px;
        }
    }

    .configuration-section-body{
        margin-top: 15px;

        .configuration-item-title{
            font-weight: bold;
           // color: rgba(white, .6);
            @include theme($themes){
                color: rgba(variable('pageText'), .7);
            }
            text-transform: uppercase;
            margin-bottom: 5px;

            &.larger{
                //color: rgba(white, .65);
                @include theme($themes){
                    color:rgba(variable('pageText'), .77) !important;
                }
                font-size: 18px;
            }
        }

        textarea{
            height: 200px;
            resize: none !important;
            padding: 6px 12px !important;

            &.is-short{
                width: 650px;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        textarea{

            &.bigright {
                padding-right: 45px !important;
            }

            padding: 14px 15px !important;

        
        }

        input[type=text],
        input[type=number], 
        textarea{
            width: 100%;
            //background-color: $pageBackground;
            @include theme($themes){
                background-color: variable('pageBackground');
                color: variable('pageText');
                box-shadow: variable('itemShadow');
            }

            &.child{
                @include theme($themes){
                    background-color: variable('boxBackground') !important;
                }
            }

            &.noshadow{
                box-shadow: none !important;
            }
            font-size: 16px;
            border: none;
            padding: 12px 15px;
            border-radius: 6px;
            outline: 0;
            border: 1px solid transparent;
            transition: .2s ease;
            resize: none;

            &:disabled{
                cursor: not-allowed !important;
            }

            &:not(:disabled){
                &:hover{
                    @include theme($themes){
                        border-color: rgba(variable('primaryBackground'), .4) !important;
                    }
                }

                &:active,
                &:focus{
                    @include theme($themes){
                        border-color: rgba(variable('primaryBackground'), .8) !important;
                    }
                }
            }

            &::placeholder{
                color: rgba(255, 255, 255, .5);
            }
        }

    }

}