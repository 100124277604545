.load-hide{
    display: none;
}
.save-box-container{
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: -125px;
    opacity: 0;
    z-index: 10;

    &.loading{
        display: none !important;
    }

    &.show {
        -webkit-animation-name: popUp;
        animation-name: popUp;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-timing-function: cubic-bezier(0.42, 0.97, 0.52, 1.49);
        animation-timing-function: cubic-bezier(0.42, 0.97, 0.52, 1.49);
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        bottom: 25px;
        opacity: 1;
    }

    &.hide {
        -webkit-animation-name: popDown;
        animation-name: popDown;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
        -webkit-animation-timing-function: cubic-bezier(0.42, 0.97, 0.52, 1.49);
        animation-timing-function: cubic-bezier(0.42, 0.97, 0.52, 1.49);
        -webkit-animation-duration: 0.3s;
        animation-duration: 0.3s;
        bottom: -125px;
    }

    .save-box{
        padding: 10px 20px;
        //background-color: $pageBackground;
        //color: white;
        font-weight: bold;
        box-shadow: 0 6px 6px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1) !important;

        @include theme($themes){
            background-color: darken(variable('boxBackground'), 3%) !important;
            color: variable('pageText') !important;
        }
    }
}

@-webkit-keyframes popUp {
    from {
        bottom: -125px;
    }

    to {
        bottom: 35px;
    }
}

@keyframes popUp {
    from {
        bottom: -125px;
    }

    to {
        bottom: 35px;
    }
}

@-webkit-keyframes popDown {
    from {
        bottom: 35px;
        opacity: 1;
    }

    to {
        bottom: -125px;
        opacity: 0;
    }
}

@keyframes popDown {
    from {
        bottom: 35px;
        opacity: 1;
    }

    to {
        bottom: -125px;
        opacity: 0;
    }
}