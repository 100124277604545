.embed-editor{
    border-radius: 4px;
    border-left: 4px solid white;
    box-shadow: $shadow !important;

    @include theme($themes){
        background-color: variable('pageBackground') !important;
    }

    padding: 15px 20px;
    min-height: 200px;

    .mini-title{
        color: rgba(white, .6) !important;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
        
        &.push{
            margin-top: 10px;
        }
    }
}