.music-playlist{
    .playlist-meta{
        background-color: $boxBackground;
        box-shadow: $shadow;
        padding: 25px;
        border-radius: 6px;
        margin-bottom: 10px;
        position: relative;

        .delete-playlist{
            position: absolute;
            top: 15px;
            right: 15px;
            width: 52px;
            height: 52px;
            background-color: transparent;
            text-align: center;
            border-radius: 6px;
            transition: .2s ease-in-out;
            cursor: pointer;

            &:hover{
                background-color: $pageBackground;
                @include theme($themes){
                    .material-symbols-rounded{
                        color: variable('primaryText') !important;
                    }
                }
            }

            .material-symbols-rounded{
                transition: .2s ease-in-out;
                line-height: 52px;
                color: white;
            }
        }

        .edit-playlist{
            width: 38px;
            height: 38px;
            text-align: center;
            display: inline-block;
            background-color: transparent;
            border-radius: 6px;
            margin-top: 30px;
            transition: .2s ease-in-out;
            cursor: pointer;

            &:hover{
                background-color: $pageBackground;
                @include theme($themes){
                    .material-symbols-rounded{
                        color: variable('primaryText') !important;
                    }
                }
            }

            .material-symbols-rounded{
                line-height: 38px;
                transition: .2s ease-in-out;
            }
        }

    
        .playlist-icon{
            width: 200px;
            height: auto;
            border-radius: 12px;
            box-shadow: $shadow;
            margin-bottom: -5px;
        }

        .playlist-name{
            font-weight: bold;
            color: white;
            font-size: 32px;
        }

        .playlist-info{
            font-size: 12px;
            margin-top: -5px;
            font-weight: bold;
            color: rgba(white, .7);
            text-transform: uppercase;
        }

        .add-to-queue{
            font-weight: bold;
            padding: 7px 15px !important;
            background-color: $pageBackground;
            display: inline-block;
            border-radius: 6px;
            box-shadow: $shadow;
            transition: .2s ease-in-out;
            cursor: pointer;
            margin-top: 30px;

            &:hover{
                @include theme($themes){
                    color: variable('primaryText');
                }
            }
        }
    }

    .playlist{
        background-color: $boxBackground;
        box-shadow: $shadow;
        padding: 10px 10px 5px 10px;
        border-radius: 6px;
    
        .playlist-item{
            background-color: $pageBackground;
            border-radius: 6px;
            padding: 10px 10px;
            margin-bottom: 5px;

            .item-icon{
                border-radius: 4px;
                width: 52px;
                height: 52px;
                margin-bottom: -7px;
            }

            .item-name{
                font-weight: bold;
                font-size: 18px;
            }

            .item-date{
                font-size: 14px;
                color: rgba(white, .7);
                margin-top: -3px;
            }

            .item-index{
                color: rgba(white, .5);
                padding-left: 7px;
            }
        }
    }
}

.tippy-box[data-theme~='playlist']{
    margin-top: -10px;
    background-color: red;           
}