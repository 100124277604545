@import "../utilities/variables";
@import "../themes/themes";

.site-container{
    padding: 0 !important;

    // .navbar{
    //     background-color: $boxBackground;
    // }

    // &::after {
    //     content: "";
    //     background: url('https://tempobot.net/images/bg.png');
    //     opacity: 0.2;
    //     top: 0;
    //     left: 0;
    //     bottom: 0;
    //     right: 0;
    //     position: fixed;
    //     width: 100vw;
    //     height: 100vh;
    //     z-index: 3;   
    //   }
}

.page{
    overflow: hidden !important;
}