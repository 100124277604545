.emoji-button-wrapper{
    position: relative;
    display: inline-block;

    .emoji-button{
        padding: 15px;
        padding-bottom: 8px;
        border-radius: 6px;
        background-color: $pageBackground !important;
        cursor: pointer;
        position: relative;
        border: 1px solid transparent;
        transition: .2s ease-in-out;
        box-shadow: $shadow  !important;

        &:hover {
            @include theme($themes) {
                border-color: rgba(variable('primaryBackground'), .4) !important;
            }
        }

        &.active,
        &:active,
        &:focus {
            @include theme($themes) {
                border-color: rgba(variable('primaryBackground'), .8) !important;
            }
        }

        .twemoji{
            width: 36px !important;
        }
    }

    .emoji-picker{
        position: absolute;
        z-index: 3;
        left: 70px;
        top: 0;

        .emoji-picker-sidebar{
            max-height: 388px !important;
            margin-top: 12px !important;
        }
        .highlighted-emoji-container{
            margin-top: 1px;
        }

        .emoji-list {
            padding-top: 60px;
        }
    }
}

img.is-36{
    width: 16px !important;
    margin-top: 14px;
}

.drop-images{
    border-radius: 6px;
    width: 100%;
    background-color: $pageBackground;
    box-shadow: $shadow;
    margin-top: 15px;

    .drop-images-header{
        background-color: darken($pageBackground, 2%);
        padding: 10px 20px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .is-config{
            background-color: $boxBackground !important;
            box-shadow: none !important;

            &.disabled{
                color: rgba(white, .4) !important;
                cursor: not-allowed;

                &:hover{
                    color: rgba(white, .4) !important;
                }
            }
        }

        .drop-images-title{
            font-size: 20px;
            font-weight: bold;
        }

        .image-count{
            &.danger{
                color: $themeRed !important;
            }
        }
    }

    .drop-images-body{
        padding: 20px;
        padding-top: 13px;
    }
}