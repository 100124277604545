@include mobile{
    .sidebar{
        position: fixed;
        top: 0;
        left: -300px;
        z-index: 22;
        box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
        0 2px 4px rgba(0,0,0,0.07), 
        0 4px 8px rgba(0,0,0,0.07), 
        0 8px 16px rgba(0,0,0,0.07),
        0 16px 32px rgba(0,0,0,0.07), 
        0 32px 64px rgba(0,0,0,0.07);

        &.show{
            left: 0;
        }
    }

    .sidebar-blur.show{
        position: fixed;
        z-index: 19;
        width: 100vw;
        height: 100vh;
        background: rgba(black, .54);
        backdrop-filter: blur(4px) !important;
        top: 0;
        left: 0;
    }
}

.sidebar{
    width: 300px;
    max-width: 300px;
    padding: 15px;
    height: 100vh;
    position: relative;
    z-index: 22;
    overflow-x: hidden !important;
   // background-color: $boxBackground;
    @include theme($themes){
        background-color: variable('boxBackground') !important;
    }
    //border: 1px solid rgba(255, 255, 255, .04);
   // border-right: 1px solid rgba(255, 255, 255, .01);
    color: white;

    .is-16{
        width: 16px !important;
        margin-top: 1px;
    }

    .new-playlist-button{
        .material-icons-round{
            font-size: 20px;
            color: rgba(white, .7) !important;
            transition: .2s ease-in-out;
        }

        &:hover{
            .material-icons-round{
                color: white !important;
                cursor: pointer !important;
            }
        }
    }
    

    .sidebar-content{
        // height: calc(100vh - 163px);
        height: calc(100vh - 492px);
        overflow: auto;

        &.mini-player{
            height: calc(100vh - 260px) !important;
        }
    }

    .sidebar-footer-container{
        position: absolute;
        bottom: 15px;
        width: 270px;

    
        .sidebar-footer{
            width: 100%;
            border-radius: 6px;
            padding: 10px;
            font-size: 14px;
            text-align: center;
            margin-top: 10px;

            @include theme($themes){
                background-color: darken(variable('pageBackground'), 2%) !important;
                color: variable('pageText') !important;
            }
        }
    }

    .link-section{
        width: 100%;

        .link-section-title{
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
          //  color: rgba(255, 255, 255, .9);
            @include theme($themes){
                color: rgba(variable('pageText'), .9) !important;
            }
            margin-bottom: 10px;
            padding: 0 15px !important;
        }

        .link-list{
            list-style-type: none;
            width: 100%;

            .sidebar-link{
                padding: 0px 15px 1px 15px !important;
                width: 100%;
                display: block;
               // color: rgba(233, 233, 233, .7);
                @include theme($themes){
                    color: rgba(variable('pageText'), .7) !important;
                }
                border-radius: 6px;
                margin-bottom: 5px;
                transition: .2s ease-in-out !important;
                cursor: pointer;

                &.is-disabled{
                    @include theme($themes){
                        color: rgba(variable('pageText'), .4) !important;
                    }
                    //color: rgba(233, 233, 233, .4);

                    .material-icons-round{
                        color: rgba(233, 233, 233, .4);
                    }

                    &:hover{
                        .material-icons-round{
                            color: white;
                        }
                    }
                }

                .material-icons-round,
                .material-symbols-rounded{
                    font-size: 26px !important;
                   // color: rgba(white, .7);
                    @include theme($themes){
                        color: rgba(variable('pageText'), .7) !important;
                    }
                    margin-top: 9px;
                    transition: .2s ease-in-out;
                }

                .link-name{
                    margin-left: 12px;
                    font-size: 14px;
                }

                &.is-active{
                    //background-color: rgba(white, .05);
                    @include theme($themes){
                        background-color: rgba(variable('pageText'), .05) !important;
                        color: variable('pageText') !important;
                    }

                    .material-icons-round{
                        //color: white !important;
                        @include theme($themes){
                            color: variable('primaryText') !important;
                        }
                    }
                    
                    &.premium-active .material-icons-round{
                        color: $premium !important;
                    }
                }

                &:not(.is-active):hover{
                    //background-color: rgba(white, .03);
                    @include theme($themes){
                        color: variable('pageText') !important;
                    }
                }
            }
        }
    }

    .guild-info {
        margin-bottom: 20px !important;
        //background: $pageBackground;
        padding-top: 10px;
        padding-bottom: 3px;
        position: relative;
        overflow: hidden;
        transition: max-width .2s ease-in-out,
        width .2s ease-in-out;
        display: block;
        cursor: default !important;
        box-shadow: none !important;;

        @include theme($themes){
            background-color: darken(variable('pageBackground'), 2%) !important;
            color: variable('pageText') !important;
        }

        &.premium{
            .guild-name{
                color: $premium !important;
            }
            .material-symbols-rounded{
                margin-right: -10px;
                margin-left: 10px;
                color: $premium !important;
            }
        }

        .fix-img{
            .react-loading-skeleton{
                margin-bottom: 10px;
            }
        }

        .guild-bg {
            background-size: cover !important;
            position: absolute;
            width: 300px;
            height: auto;
            background-position: center;
            top: -110px;
            left: 0;
            filter: blur(15px) brightness(50%);
            opacity: .6;

            display: none !important;

            transition: max-width .2s ease-in-out,
            width .2s ease-in-out;

            &:before {
                width: inherit;
                height: inherit;
                position: fixed;
                top: 0;
                left: 0;
                background: rgba(11, 11, 11, .3);
                z-index: -1;
            }
        }

        .columns {
            filter: blur(0px);
        }

        .guild-icon {
            width: 52px;
            height: 52px;
            border-radius: 100%;
            //box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
            transition: margin-left .2s ease-in-out;
        }

        .guild-name {
            font-weight: bold;
            font-size: 20px;
            margin-left: 15px;
            //text-shadow: 2px 2px 10px rgba(0,0,0,.3);
            max-width: 100%;
            width: 100%;
            margin-top: -5px;
            font-family: $poppins !important;
        }
    }

}
