.discord-chat{
    width: 750px;
    height: 450px;
    max-height: 450px;
    background-color: $boxBackground;
    border-radius: 6px;

    .chat-box{
        padding: 5px 0;

        .chat-message{
            padding: 3px 10px;
            width: 100%;

            .message-avatar{
                width: 40px;
                height: 40px;
                border-radius: 100%;
            }

            .message-name{
                color: white;
                font-weight: bold;
            }

            .message-content{
                color: #DCDDDE;
                margin-top: -5px;
            }

            &:hover{
                background-color: rgba($pageBackground, .15);
            }
        }
    }

    .channel-sidebar{
        background-color: darken($boxBackground, 2%) !important;
        width: 175px;
        height: 450px;
        max-height: 450px;
        
        .server-name{
            padding: 5px 10px;
            color:rgba(white, .9);
            font-weight: bold;
            font-size: 20px;
        }

        .server-separator{
            width: 175px;
            height: 2px;
            background-color: rgba($pageBackground, .5) !important;
        }

        .channel-container{
            padding: 10px;

            .channel{
                padding: 3px 6px 0 6px !important;
                border-radius: 4px;
                background-color: rgba(white, .06) !important;
                height: 31px;

                .material-icons-round{
                    color: rgba(white, .9);
                    margin-bottom: -10px !important;
                }

                .channel-name{
                    color: rgba(white, .85);
                }
            }
        }
    }

    .left-sidebar{
        background-color: darken($boxBackground, 3%) !important;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        height: 450px;
        max-height: 450px;
        padding: 10px;

        .control-buttons{
            display: block !important;
            position: absolute;

            .control-button{
                width: 12px;
                height: 12px;
                border-radius: 100%;
                float: left;
                display: inline-block;

                &.left-button{
                    background-color: #FF5753;
                }

                &.middle-button{
                    background-color: #E8C117;
                    margin: 0 4px;
                }

                &.right-button{
                    background-color: #55C139;
                }
            }
        }

        .server-icon{
            width: 44px;
            height: 44px;
            background-size: 44px 44px;
            margin-top: 25px;
            border-radius: 10px;
        }

    }

}