$themes: (
    purple: (
        primaryBackground: $themePurple,
        primaryHoverBackground: darken($themePurple, 3%),
        primaryLight: lighten($themePurple, 5%),
        primaryText: $themePurple,
        secondaryText: white,
        logo: url("/images/logo.png"),

        pageBackground: $pageBackground,
        pageText: white,
        boxBackground: $boxBackground,
        boxBackgroundSecondary: white,
        navbarShadow: 0 4px 4px -4px rgba(0,0,0,.3),
        boxShadow: $shadow,
        itemShadow: $shadow,
    ),

    yellow: (
        primaryBackground: $themeYellow,
        primaryHoverBackground: darken($themeYellow, 3%),
        primaryLight: lighten($themeYellow, 5%),
        primaryText: $themeYellow,
        secondaryText: $boxBackground,
        logo: url("/images/tempo_2.webp"),

        pageBackground: $pageBackground,
        pageText: white,
        boxBackground: $boxBackground,
        boxBackgroundSecondary: white,
        navbarShadow: 0 4px 4px -4px rgba(0,0,0,.3),
        boxShadow: $shadow,
        itemShadow: $shadow,
    ),

    red: (
        primaryBackground: $themeRed,
        primaryHoverBackground: darken($themeRed, 3%),
        primaryLight: lighten($themeRed, 5%),
        primaryText: $themeRed,
        secondaryText: white,
        logo: url("/images/tempo_6.webp"),

        pageBackground: $pageBackground,
        pageText: white,
        boxBackground: $boxBackground,
        boxBackgroundSecondary: white,
        navbarShadow: 0 4px 4px -4px rgba(0,0,0,.3),
        boxShadow: $shadow,
        itemShadow: $shadow,
    ),

    blue: (
        primaryBackground: $themeBlue,
        primaryHoverBackground: darken($themeBlue, 3%),
        primaryLight: lighten($themeBlue, 5%),
        primaryText: $themeBlue,
        secondaryText: white,
        logo: url("/images/tempo_4.webp"),

        pageBackground: $pageBackground,
        pageText: white,
        boxBackground: $boxBackground,
        boxBackgroundSecondary: white,
        navbarShadow: 0 4px 4px -4px rgba(0,0,0,.3),
        boxShadow: $shadow,
        itemShadow: $shadow,
    ),

    aqua: (
        primaryBackground: $themeAqua,
        primaryHoverBackground: darken($themeAqua, 3%),
        primaryLight: lighten($themeAqua, 5%),
        primaryText: $themeAqua,
        secondaryText: #111,
        logo: url("/images/tempo_5.webp"),

        pageBackground: $pageBackground,
        pageText: white,
        boxBackground: $boxBackground,
        boxBackgroundSecondary: white,
        navbarShadow: 0 4px 4px -4px rgba(0,0,0,.3),
        boxShadow: $shadow,
        itemShadow: $shadow,
    ),

    green: (
        primaryBackground: $themeGreen,
        primaryHoverBackground: darken($themeGreen, 3%),
        primaryLight: lighten($themeGreen, 5%),
        primaryText: $themeGreen,
        secondaryText: $boxBackground,
        logo: url("/images/tempo_3.webp"),

        pageBackground: $pageBackground,
        pageText: white,
        boxBackground: $boxBackground,
        boxBackgroundSecondary: white,
        navbarShadow: 0 4px 4px -4px rgba(0,0,0,.3),
        boxShadow: $shadow,
        itemShadow: $shadow,
    ),

    blurple: (
        primaryBackground: $themeBlurple,
        primaryHoverBackground: darken($themeBlurple, 3%),
        primaryLight: lighten($themeBlurple, 5%),
        primaryText: $themeBlurple,
        secondaryText: white,
        logo: url("/images/tempo_dev.png"),

        pageBackground: $pageBackground,
        pageText: white,
        boxBackground: $boxBackground,
        boxBackgroundSecondary: white,
        navbarShadow: 0 4px 4px -4px rgba(0,0,0,.3),
        boxShadow: $shadow,
        itemShadow: $shadow,
    ),

    // purple: (
    //     primaryBackground: $themePurple,
    //     primaryHoverBackground: darken($themePurple, 3%),
    //     primaryLight: lighten($themePurple, 5%),
    //     primaryText: $themePurple,
    //     secondaryText: white,
    //     logo: url("/images/logo.webp")
    // ),
    // yellow: (
    //     primaryBackground: $themeYellow,
    //     primaryHoverBackground: darken($themeYellow, 3%),
    //     primaryLight: lighten($themeYellow, 5%),
    //     primaryText: $themeYellow,
    //     secondaryText: $boxBackground,
    //     logo: url("/images/tempo_2.webp")
    // ),
    // red: (
    //     primaryBackground: $themeRed,
    //     primaryHoverBackground: darken($themeRed, 3%),
    //     primaryLight: lighten($themeRed, 5%),
    //     primaryText: $themeRed,
    //     secondaryText: white,
    //     logo: url("/images/tempo_6.webp")
    // ),
    // blue: (
    //     primaryBackground: $themeBlue,
    //     primaryHoverBackground: darken($themeBlue, 3%),
    //     primaryLight: lighten($themeBlue, 5%),
    //     primaryText: $themeBlue,
    //     secondaryText: white,
    //     logo: url("/images/tempo_4.webp")
    // ),
    // aqua: (
    //     primaryBackground: $themeAqua,
    //     primaryHoverBackground: darken($themeAqua, 3%),
    //     primaryLight: lighten($themeAqua, 5%),
    //     primaryText: $themeAqua,
    //     secondaryText: #111,
    //     logo: url("/images/tempo_5.webp")
    // ),
    // green: (
    //     primaryBackground: $themeGreen,
    //     primaryHoverBackground: darken($themeGreen, 3%),
    //     primaryLight: lighten($themeGreen, 5%),
    //     primaryText: $themeGreen,
    //     secondaryText: $boxBackground,
    //     logo: url("/images/tempo_3.webp")
    // ),
    // blurple: (
    //     primaryBackground: $themeBlurple,
    //     primaryHoverBackground: darken($themeBlurple, 3%),
    //     primaryLight: lighten($themeBlurple, 5%),
    //     primaryText: $themeBlurple,
    //     secondaryText: white,
    //     logo: url("/images/tempo_dev.png")
    // )
);

@mixin theme($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function variable($key) {
    @return map-get($theme-map, $key);
}
