.autocomplete-textarea-container{
    position: relative;

    .emoji-pop{
        position: absolute;
        top: 10px;
        right: 12px;

        .emoji-picker{
            left: 40px;
            top: -10px;
        }
    }

    .autocomplete-content{
        position: absolute;
        left: 0;
        bottom: 100%;
        background-color: darken($pageBackground, 2%);
        width: 100%;
        padding: 5px 15px;
        z-index: 5;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .search-text{
            font-weight: bold;
            text-transform: uppercase;
            color: rgba(white, .8);
            font-size: 18px;

            .search{
                color: white;
                margin-left: 5px;
            }
        }

        .column{
            overflow-x: scroll !important;
            -webkit-overflow-scrolling: touch;
        }

        ul{
            list-style-type: none;
            max-width: 100%;

            li{
                display: inline-block;
                padding: 3px 10px;
                border-radius: 6px;
                transition: .2s ease-in-out;
                background-color: rgba(white, .04);
                margin-right: 5px;
                margin-bottom: 2px;
                cursor: pointer;

                &:hover{
                    background-color: rgba(white, .08);
                }
            }
        }
    }

    .textarea{
        position: relative;
        border: none !important;

        &.suggestions-open{
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
} 