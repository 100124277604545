.admin-sidebar{
    background-color: $boxBackground;
    box-shadow: $shadow;
    border-radius: 6px;
    padding: 10px 10px 5px 10px;
    width: 275px !important;
    position: relative;
    z-index: 99;

    .admin-sidebar-link{
        border-radius: 4px;
        padding: 10px 15px;
        color: rgba(white, .8);
        transition: .2s ease-in-out;
        cursor: default;
        width: 100% !important;
        display: block !important;
        margin-bottom: 5px;
        

        &:not(.is-active):hover{
            cursor: pointer;
        }

        &:hover{
            //background-color: rgba(white, .04);
            color: white;
        }

        &.is-active{
            background-color: rgba(white, .05);
            color: white;
        }
    }

}