.create-command{
    background-color: $boxBackground !important;
    box-shadow: $shadow !important;
    font-weight: bold;
    transition: .2s ease-in-out;

    &.is-disabled{
        cursor: not-allowed !important;
        opacity: .7;
    }

    &.light{
        background-color: $pageBackground !important;
    }

    &:not(.is-disabled):hover{
        @include theme($themes){
            background-color: variable('primaryBackground') !important;
        }
    }
}

.create-command-section{
    .back-arrow{
        font-size: 32px;
        cursor: pointer;
        transition: .2s ease-in-out;
        transform: translateX(0);

        &:hover{
            transform: translateX(-5px);
        }
    }
}

.option-toggle{
    padding: 10px 15px;
    background-color: $pageBackground;
    box-shadow: $shadow;
    border-radius: 6px;
    margin-top: 10px;

    .toggle-text{
        color: white;
    }
}

.list-command{
    padding: 15px 25px !important;
    background-color: $boxBackground !important;
    box-shadow: $shadow !important;
    border-radius: 6px;
    margin-bottom: 10px;

    &.is-disabled{
        .delete-command{
            cursor: not-allowed !important;
            &:hover{
                color: white !important;
            }
        }
    }

    .command-name{
        font-weight: bold;
        font-size: 24px;
    }

    .delete-command{
        font-size: 32px;
        cursor: pointer;
        transition: .2s ease-in-out;
        margin-top: 5px;

        &:hover{
            color: $themeRed !important;
        }
    }

    .edit-arrow{
        font-size: 32px;
        cursor: pointer;
        transition: .2s ease-in-out;
        transform: translateX(0);
        margin-top: 5px;

        &:hover{
            transform: translateX(5px);
        }
    }
}