:root {
  --highlight-width: auto;
  --highlight-x-pos: 0;
}

.segmented-control-container{

    .segmented-control{
        display: inline-flex;
        justify-content: space-between;

       // background-color: $pageBackground;

        &.is-box-theme{
            background-color: $boxBackground !important;
            box-shadow: $shadow !important;
            margin-bottom: 15px !important;
            //width: 100% !important;
        }

        @include theme($themes){
            background-color: variable('pageBackground') ;
            box-shadow: variable('itemShadow');
            color: rgba(variable('pageText'), .85) !important;
        }


       // box-shadow: 0 6px 6px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        border-radius: 6px;
        padding: 0 6px;
        margin: auto;
        overflow: auto !important;
        position: relative;
       // color: rgba(white, .85);
        text-align: center;

        &.premium::before{
            transition: background 0.3s ease-in-out, transform 0.3s ease, width 0.3s ease;
            content: '';
            @include theme($themes){
                background: $premium !important;
            }
            box-shadow: 0 6px 6px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
            border-radius: 4px;
            width: var(--highlight-width);
            transform: translateX(var(--highlight-x-pos));
            position: absolute;
            top: 6px;
            bottom: 6px;
            left: 0;
            z-index: 0;
        }

        &:not(.premium)::before {
            transition: background 0.3s ease-in-out, transform 0.3s ease, width 0.3s ease;
            content: '';
            @include theme($themes){
                background: variable('primaryBackground') !important;
            }
            box-shadow: 0 6px 6px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
            border-radius: 4px;
            width: var(--highlight-width);
            transform: translateX(var(--highlight-x-pos));
            position: absolute;
            top: 6px;
            bottom: 6px;
            left: 0;
            z-index: 0;
        }

        input{
            opacity: 0;
            margin: 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            width: 100%;
            cursor: pointer;
            height: 100%;
        }

        .segment{
            min-width: 130px;
            position: relative;
            text-align: center;
            z-index: 1;

            &.disabled{
                label{
                    cursor: not-allowed !important;
                }
            }

            &:not(.disabled):hover{
                @include theme($themes){
                    color: variable('pageText') !important;
                }
                //color: white !important;
            }

            label{
                cursor: pointer;
                display: block;
                font-weight: bold;
                padding: 10px;
                position: relative;
                transition: color .2s ease-in-out;
            }

            &.active{
                label{
                    @include theme($themes){
                        color: variable('secondaryText') !important;
                    }
                }
            }
        }

        &.premium{
            .segment{
                &.active{
                    label{
                        color: $boxBackground !important;
                    }
                }
            }
        }
    }
}