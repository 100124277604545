.page-title{
    font-size: 32px;
    color: white;
    font-weight: bold;
    font-family: $poppins !important;
}

.page-subtitle{
    color: rgba(white, .7);
    margin-top: 15px;
}

.react-select__single-value{
    color: white !important;
    width: 200px;
}

.react-select__menu{
    background: lighten($boxBackground, 2%) !important;
    box-shadow: $shadow;
}