.react-tour{
    background-color: $pageBackground !important;
    border-radius: 6px;
    box-shadow: $shadow !important;
    padding: 15px 20px !important;
    color: white !important;
}

.react-tour-mask, .react-tour-mask-highlighted{
    border-radius: 6px;
}

.css-11p5tv2-Close{
    top: 3px !important;
    right: 5px !important;
}

.css-3h4r3a-Badge{
    padding-left: 0.625em !important;
    padding-right: 0.625em !important;
    background-color: $themePurple !important;
}

.css-1241u7d-Navigation{
    background-color: $themePurple !important;
}
.css-1m0cjfg-Navigation{
    margin-top: 15px !important;
}