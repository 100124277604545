.button-control{
    padding: 6px;
    background: $boxBackground;
    box-shadow: $shadow;
    border-radius: 6px;
    
    &.box-theme{
        background: $pageBackground;
    }
    
    &.premium{
        
        .button {
            &:not(.active):hover{
                background: rgba($pageBackground, .6) !important;
            }
            &.active {
                background: $pageBackground !important;
                color: $premium;
                box-shadow: $shadow;
        
            }
        }
    }
    
    .button{
        background: transparent;
        border: none;
        border-radius: 4px;
        color: rgba(white, .6);
        font-weight: bold;
        text-transform: uppercase;
        padding: 0 15px;
        min-width: 100px;
        height: 35px !important;
        transition: .2s ease-in-out;
        
        &:not(.active):hover{
            background: rgba(white, .08);
            color: rgba(white, .8);
        }
        
        &.active{
            color: white;
            @include theme($themes){
                background: variable('primaryBackground');
            }
        }
    }
}