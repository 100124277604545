
.docs-sidebar{
    width: 300px;
    max-width: 300px;
    padding: 10px;
    position: relative;
    z-index: 22;
    height: calc(100vh - 93px);

    @include theme($themes){
        background-color: darken(variable('boxBackground'), 2%) !important;
        color: rgba(variable('pageText'), .9) !important;
    }

    .sidebar-content{
        height: calc(100vh - 163px);
        overflow: auto;
    }

    .sidebar-footer{
        width: 100%;
        border-radius: 6px;
        padding: 10px;
        font-size: 14px;
        text-align: center;

        @include theme($themes){
            background-color: darken(variable('pageBackground'), 2%) !important;
            color: variable('pageText') !important;
        }
    }


    .link-section{
        width: 100%;
        border-radius: 6px !important;
        padding: 5px;

        .link-section-title{
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            @include theme($themes){
                color: rgba(variable('pageText'), .9) !important;
            }
            margin-bottom: 5px;
            padding: 0 20px !important;
        }

        .new-page-button{
            .material-icons-round{
                font-size: 18px;
                color: rgba(white, .7) !important;
                transition: .2s ease-in-out;
            }

            &:hover{
                .material-icons-round{
                    color: white !important;
                    cursor: pointer !important;
                }
            }
        }

        .link-list{
            list-style-type: none;
            width: 100%;
            margin-bottom: 10px;

            .sidebar-link{
                padding: 5px 20px !important;
                width: 100%;
                border-radius: 6px !important;
                display: block;
                @include theme($themes){
                    color: rgba(variable('pageText'), .7) !important;
                }
                border-radius: 6px;
                margin-bottom: 5px;
                transition: .2s ease-in-out !important;
                cursor: pointer;
                transform: translate(0, 0);
                opacity: 0.999 !important;
                position: relative !important;

                
                

                &.is-disabled{
                    @include theme($themes){
                        color: rgba(variable('pageText'), .4) !important;
                    }

                    .material-icons-round{
                        color: rgba(233, 233, 233, .4);
                    }

                    &:hover{
                        .material-icons-round{
                            color: white;
                        }
                    }
                }

                .material-icons-round{
                    font-size: 26px !important;
                    @include theme($themes){
                        color: rgba(variable('pageText'), .7) !important;
                    }
                    margin-top: 9px;
                    transition: .2s ease-in-out;

                    opacity: 0;

                    &:hover{
                        cursor: pointer;
                        color: white !important;
                    }
                }

                &:hover{
                    .material-icons-round{
                        opacity: 1 !important;
                    }
                }

                .link-name{
                    margin-left: 12px;
                    font-size: 14px;
                }

                &.is-active{
                    @include theme($themes){
                        background-color: rgba(variable('pageText'), .05) !important;
                        color: variable('pageText') !important;
                    }
                }

                &:not(.is-active):hover{
                    @include theme($themes){
                        color: variable('pageText') !important;
                    }
                }
            }
        }
    }
}

#page-edit-modal{
    // width: 100vw !important;
    // height: 100vh !important;
    // min-width: 100vh !important;
    // min-height: 100vh !important;

    .content-card{
        width: 100vw !important;
        height: 100vh !important;
        min-width: 100vh !important;
        min-height: 100vh !important;
        border-radius: 0 !important;
    }
}