.setting-toggles{
    .toggle-column{
        margin-bottom: -25px;
    }
    .setting-toggle{
        padding: 5px 0;

        .toggle-text{
            font-weight: bold;
            font-size: 17px;
        }

        .toggle-description{
            color: rgba(white, .7);
            //margin-top: -20px;
            font-size: 14px;
            font-weight: normal;
        }
    }
}