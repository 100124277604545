.commands-list{

    .loader-6 {
        height: 20px;
        width: 20px;
        display: inline-block;
        margin-left: 10px;
        padding-top: 2px;
    }
    .loader-6 span {
        display: block;
        height: 20px;
        width: 20px;
    }
    .loader-6 span::before,
    .loader-6 span::after {
        content: "";
        display: block;
        position: absolute;
        
        height: 20px;
        width: 20px;
        border: 2px solid #FFF;
        border-radius: 50%;
        opacity: 0;
        -webkit-animation: loader-6-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
                animation: loader-6-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
    }
    @-webkit-keyframes loader-6-1 {
        0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
        100% { -webkit-transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
    }
    @keyframes loader-6-1 {
        0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
        100% { transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
    }
    .loader-6 span::after {
        -webkit-animation: loader-6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
                animation: loader-6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
    }
    @-webkit-keyframes loader-6-2 {
        0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
        100% { -webkit-transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
    }
    @keyframes loader-6-2 {
        0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
        100% { transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
    }

    .commands-list-title{
        font-weight: bold;
        font-size: 24px;
        color: white;
        margin-top: 35px;
        margin-bottom: 10px;
    }

    .command{
        padding: 15px 20px;
        border-radius: 6px;
        background-color: $boxBackground;
        box-shadow: $shadow;
        width: 100%;
        cursor: pointer;
        border: 1px solid transparent;
        transition: .2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &.enabled:hover{
            @include theme($themes){
                border-color: variable('primaryBackground') !important;
            }

            .command-name{
                @include theme($themes){
                    color: variable('primaryText') !important;
                }
            }
        }

       &.disabled{
           opacity: .7;

           &:hover{
               opacity: 1;
           }
       }

        .command-name{
            font-size: 20px;
            font-weight: bold;
            transition: .2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }

        .command-desc{
            font-size: 14px;
            margin-top: -3px;
            color: rgba(white, .6);
        }
    }

    .command-configuration-modal{
        .content-card{
            background-color: $pageBackground;
            padding: 20px 25px;
        }
        .modal-title{
            font-size: 22px;
            font-weight: bold;
            color: white;
            text-transform: uppercase;
        }

        .command-config-section{
            padding: 10px 15px;
            border-radius: 4px;
            background-color: $boxBackground;
            box-shadow: $shadow;
            margin-bottom: 10px;

            .section-title{
                font-weight: bold;
                color: white;
                font-size: 18px;
            }

            .section-desc{
                color: rgba(white, .7);
                margin-top: -2px;
                font-size: 14px;
                margin-bottom: 10px;
            }

            
        }
    }

}