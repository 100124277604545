.commands-sidebar{
    width: 250px !important;
    padding: 10px;

    .sidebar-link{
        width: 100%;
        padding: 7px 15px;
        color: rgba(white, .6);
        border-radius: 4px;
        transition: .2s ease-in-out;
        cursor: default;

        &.search{
            padding: 0;
            margin-bottom: 3px;

            &.is-active{
                input{
                    background-color: $pageBackground !important;
                    color: white !important;
                    box-shadow: $shadow !important;
                    &::placeholder{
                        color: white !important;
                    }
                }
            }

            &:hover{
                input{
                    &::placeholder{
                        color: white !important;
                    }
                    color: white !important;
                    cursor: pointer !important;
                }
            }

            input{
                width: 100%;
                padding: 10px 15px !important;
                border-radius: 4px;
                transition: .2s ease-in-out;
                color: white !important;
                font-size: 16px;
                border: none !important;

                &::placeholder{
                    color: rgba(white, .6) !important;
                    transition: .2s ease-in-out;
                }
            }
        }

        &:not(:last-of-type){
            margin-bottom: 3px;
        }

        &.is-active{
            background-color: $pageBackground !important;
            color: white !important;
        }

        &:not(.is-active):hover{
            cursor: pointer;
        }

        &:hover{
            color: white;
        }
    }
}
.expand-button{
    width: 45px;
    height: 45px;
    border-radius: 6px;
    background: $boxBackground;
    box-shadow: $shadow;
    text-align: center;
    cursor: pointer;

    .material-symbols-rounded{
        line-height: 45px;
        color: rgba(white, .6);
        transition: .2s ease-in-out;
    }

    &:hover{
        .material-symbols-rounded{
            @include theme($themes){
                color: variable('primaryText');
            }
        }
    }
}
.commands{

    .command{
        background: $boxBackground;
        box-shadow: $shadow;
        border-radius: 6px;
        padding: 10px 15px;
        margin-bottom: 5px;
        cursor: pointer;
        max-height: 45px;
        overflow: hidden;

        .slash-box, .star-box{
            background: $pageBackground;
            color: rgba(white, .4);
            font-weight: 900;
            width: 25px;
            height: 25px;
            border-radius: 6px;
            text-align: center;
            line-height: 1.7;
            transition: .2s ease-in-out;
        }

        .command-trigger{
            font-weight: bold;
            color: white;
        }

        .command-arguments{
            color: rgba(white, .4);
        }

        .command-info{
            margin-top: -15px;
            padding: 0 10px;

            .command-description{
                font-weight: bold;
                color: white;
            }
        }

        .material-symbols-rounded{
            transform: rotate(0deg);
            margin-top: 1px;
            color: rgba(white, .6);
            transition: .2s ease-in-out;
        }

        &:hover, &.active{
            .star-box{
                color: $premium;
            }

            .slash-box{
                @include theme($themes){
                    color: variable('primaryText') !important;
                }
            }
        }

        &.active{
            .material-symbols-rounded{
                transform: rotate(180deg);
                color: rgba(white, .8);
            }
        }

    }
}