.support-bar{
    width: 100vw;
    padding: 15px 50px;
    background-color: darken($boxBackground, 3%) !important;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;

    .support-time-remaining{
        font-weight: bold;
        color: white;
        font-size: 18px;
    }
}