.music-player-control-container{
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 66px;
    opacity: 1;
    z-index: 10;

    .column.nopad{
        padding-top: 0;
        padding-bottom: 0px;
    }

    .music-player-control{
        width: 100%;
        padding: 16px 5px;
        border-radius: 6px;
        background-color: $boxBackground;
        box-shadow: $shadow;
        position: relative;
        z-index: 12;

        .music-player-controls{

            .player-button{
                border-radius: 4px;
                background-color: rgba($pageBackground, .8);

                transition: background-color .2s ease-in-out, transform .5s;
                -webkit-transition-timing-function: cubic-bezier(0.36, 1, 0.46, 1);
                transition-timing-function: cubic-bezier(0.36, 1.68, 0.46, 1);

                .material-symbols-rounded{
                    transition: background-color .2s ease-in-out, transform .5s;
                    -webkit-transition-timing-function: cubic-bezier(0.36, 1, 0.46, 1);
                    transition-timing-function: cubic-bezier(0.36, 1.68, 0.46, 1);
                }

                &.skip-previous,
                &.skip-next{
                    height: 38px;
                    width: 38px;
                    text-align: center;
                    cursor: pointer;

                    .material-symbols-rounded{
                        line-height: 38px;
                    }
                }

                &.play-pause{
                    width: 48px;
                    height: 48px;
                    text-align: center;
                    cursor: pointer;

                    .material-symbols-rounded{
                        line-height: 48px;
                        font-size: 34px;
                    }
                }

                &:hover{
                    transform: scale(1.1);

                    .material-symbols-rounded{
                     //   transform: scale(1.1);
                    }
                }
            }

            .button-circle{
                border-radius: 100%;
                background-color: rgba($pageBackground, .8);
                height: 35px;
                width: 35px;
                text-align: center;
                cursor: pointer;

                transition: background-color .2s ease-in-out, transform .5s;
                -webkit-transition-timing-function: cubic-bezier(0.36, 1, 0.46, 1);
                transition-timing-function: cubic-bezier(0.36, 1.68, 0.46, 1);

                &:hover{
                    transform: scale(1.1);
                    .material-symbols-rounded{
                        transform: scale(1.1);
                    }
                    //background-color: $pageBackground;
                }
                
                .material-symbols-rounded{
                    margin: auto auto !important;
                    font-size: 24px;
                    font-weight: 300;
                    line-height: 35px;
                    transition: background-color .2s ease-in-out, transform .5s;
                    -webkit-transition-timing-function: cubic-bezier(0.36, 1, 0.46, 1);
                    transition-timing-function: cubic-bezier(0.36, 1.68, 0.46, 1);
                }
            }

            .seekbar{
                width: 100%;
                height: 5px;
                content: '';
                border-radius: 5px;
                background-color: $pageBackground;
                margin-top: -5px;
                margin-bottom: -13px;

                &:after{
                    content: '';
                    width: 50px;
                    height: 5px;
                    border-radius: 5px;
                    background-color: $themePurple;
                    position: relative;
                    z-index: 99;
                }
            }
        }

        .music-player-control-volume{
            background-color: $pageBackground;
            border-radius: 6px;
            height: 60px;
            margin-top: 11px;
            width: 276px;

            .tempo-range-track{
                width: calc(100% - 25px);
                height: 8px;
                content: '';
                border-radius: 8px;
                background-color: $boxBackground;

                &.anim{
                    transition: background .2s ease-in-out !important;
                }
            }

            .tempo-range-thumb{
                width: 12px;
                height: 12px;
                border-radius: 8px;
                background-color: lighten($boxBackground, 8%);
                box-shadow: $shadow !important;
                content: '';
                z-index: 3;
                transform: translateY(-2px);
                border: none !important;
                //box-shadow: none !important;
                outline: 0 !important;
                transition: background .2s ease-in-out;

                &.anim{
                    transition: .2s ease-in-out !important;
                }

                &:hover{
                    background-color: lighten($boxBackground, 10%);
                }
            }

            .volume-icon{
                width: 60px;
                height: 60px;
                background-color: darken($pageBackground, 2%);
                text-align: center;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                cursor: pointer;

                transition: .2s ease-in-out;

                .material-symbols-rounded{
                    font-size: 30px;
                    line-height: 60px;
                }

                &:hover{
                    background-color: darken($pageBackground, 3%);
                }
            }
        }


        .music-player-control-requester{
            background-color: $pageBackground;
            border-radius: 6px;
            width: 276px;

            .requester-avatar{
                width: 60px;
                height: 60px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                margin-bottom: -7px;
            }

            .requested-by{
                font-size: 12px;
                font-weight: bold;
                color: rgba(white, .7);
                text-transform: uppercase;
                margin-bottom: -8px;
                margin-top: 4px;
            }

            .requester-name{
                color: white;
                font-size: 22px;
                font-weight: bold;

                .discriminator{
                    color: rgba(white, .4);
                    font-size: 14px;
                }
            }
        }
    }
}