body, html{
    width: 100vw;
    height: 100vh;
    font-family: $proxima-nova !important;
    font-weight: 500 !important;
    overflow: hidden;
}

div#root,
.page-root{
    height: 100vh !important;
}
// Content Cards
.content-card{
    @include theme($themes){
        background-color: variable('boxBackground');
    }
    box-shadow: none;
    border: none;
    border-radius: 6px;
    padding: 15px;
}

.material-symbols-rounded {
    font-variation-settings:
        'FILL' 0,
        'wght' 700,
        'GRAD' 0,
        'opsz' 48
}

.page-container{
    height: calc(100vh - 73px) !important;
    overflow: auto !important;
    padding: 30px 35px;
    margin: 0 auto !important;

    @include theme($themes){
        background-color: variable('pageBackground') !important;
        color: variable('pageText') !important;
    }

    .container.base{
        margin: 0 !important;
    }
}

.primary-text{
    transition: .2s ease-in-out;

    @include theme($themes){
        color: variable('primaryText') !important;
    }
}

.site-container{
    padding: 20px !important;
    max-height: 100vh;
    overflow-y: auto;
    @include theme($themes){
        background-color: variable('pageBackground') !important;
    }

    .page{
        position: sticky;
        z-index: 19;
        overflow: scroll;
        min-height: calc(100vh - 93px) !important;
    }
}

.noselect, .noselect * {
    -webkit-touch-callout: none !important; /* iOS Safari */
      -webkit-user-select: none !important; /* Safari */
       -khtml-user-select: none !important; /* Konqueror HTML */
         -moz-user-select: none !important; /* Old versions of Firefox */
          -ms-user-select: none !important; /* Internet Explorer/Edge */
              user-select: none !important; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.page-enter {
    opacity: 0;
    transform: scale(1.1);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    display: none;
}

.page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.loader-enter{
    opacity: 0;
 }
 .loader-enter-active {
   opacity: 1;
   transition: opacity 300ms ease-in-out;
 }
 .loader-exit {
   display: none;
 }
 .loader-exit-active {
   opacity: 0;
   transition: opacity 3000ms ease-in-out;
 }

 .checkout-close{
    position: absolute;
    top: 0;
    right: 40px;
    z-index: 999999;
    background: #d22672;
 }


.notfound-page{
    padding: 150px;
    padding-bottom: 250px;

  .error{
    font-size: 42px;
    font-weight: bold;
    color: white;
  }

  .error-small{
    font-size: 18px;
    color: rgba(white, .6);
  }

  .button-container{
    position: relative;
    padding: 40px;
    display: block;
    margin: -40px;
    width: 250px;
  }
}

.torch {
  margin: -150px 0 0 -150px;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 0 9999em #000000f7;
  opacity: 1;
  border-radius: 50%;
  position: fixed;
  background: rgba(0,0,0,0.3);
  z-index: 9999;

  &:after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    box-shadow: inset 0 0 40px 2px #000,
    0 0 20px 4px rgba(13,13,10,0.2);
  }
}