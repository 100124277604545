$poppins: 'Poppins', sans-serif;
$codacaption: 'Coda Caption', sans-serif;
$nunitoSans: 'Nunito Sans', sans-serif;
$proxima-nova: 'Proxima-Nova', sans-serif;

$pageBackground: #181b24;
// $pageBackground: #181b24;

$boxBackground: rgb(38, 39, 49);
// $boxBackground: #252537;
// $boxBackground: #232337;


// $boxBackground: #232337;
// $boxBackground: #282c35;
$primary: #9671ee;
$primaryText: #9671ee;

$themePurple: #9254ff;
$themeRed: #ED4245;
$themeBlue: #1E90FF;
$themeYellow: #FEE75C;
$themeAqua: rgb(51, 209, 209);
$themeGreen: #57F287;
$themeBlurple: #7289da;
$premium: #fcbf19;

$shadow: 0 6px 6px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);