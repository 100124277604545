.notification-list-item{
    margin-bottom: 10px;
    cursor: pointer;
    padding: 15px;

    &:hover{
        .arrow{
            transform: translateX(5px);
        }
    }
    .notification-list-item-title{
        font-size: 20px;
    }

    .notification-list-posted{
        font-size: 14px;
        color: rgba(white, .7);
        margin-top: -3px;
    }

    &.unread{
        .notification-list-item-title{
            font-weight: bold;
        }
    }

    .arrow{
        font-size: 32px;
        cursor: pointer;
        transition: .2s ease-in-out;
        transform: translateX(0);
        margin-top: 5px;
    }
}

.t-notification{

    .notification-title{
        font-weight: bold;
        font-size: 28px;
    }

    .notification-posted{
        color: rgba(white, .6);
        margin-top: -5px;
    }

    .back-arrow{
        font-size: 32px;
        cursor: pointer;
        transition: .2s ease-in-out;
        transform: translateX(0);
        margin-top: 5px;

        &:hover{
            transform: translateX(-5px);
        }
    }

    hr{
        margin-top: -10px;
        margin-bottom: 10px;
        background-color: rgba(white, .08);
        height: 1px;
    }

    .notification-content{
        color: white;
    }
    
}