.profile-enter {
    opacity: 0;
  }
.profile-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.profile-exit {
    display: none !important;
}
.profile-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

.menu-primary-enter {
    position: absolute;
    width: 94%;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all 0.2s ease;
  }
  .menu-primary-exit {
    width: 94%;
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all 0.2s ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all 0.2s ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all 0.2s ease;
  }

.profile{
    padding: 0 !important;
    margin: 0 !important;

    &:hover{
        .user-profile{
            background-color: rgba(255, 255, 255, .03);
            cursor: pointer;
        }

        .navbar-dropdown{
            -webkit-transform: scale(1) translateY(0);
            -ms-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
            opacity: 1;
            visibility: visible !important;
            //height: auto !important;
        }
    }

    .user-profile{
        padding: 10px 15px !important;
        padding-right: 25px !important;
        border-radius: 6px;
        transition: background .2s ease-in-out;
        width: 100%;
        margin-top: 3px;

        .user-icon-container{
            position: relative;

            &.user{
                .user-icon-badge{
                    display: none;
                }

                .user-icon{
                    width: 42px !important;
                    height: 42px !important;
                    border: none !important;
                }
            }

            &.developer{ 
                .user-icon{ border-color: $themePurple; }
                .user-icon-badge{ background-color: $themePurple; }
            }

            &.communitymanager{ 
                .user-icon{ border-color: #00acff; }
                .user-icon-badge{ background-color: #00acff; 
                    .user-role-badge::after{ color: #111 !important; }
                }
            }

            &.moderator{ 
                .user-icon{ border-color: #4be9fd; }
                .user-icon-badge{ background-color: #4be9fd;
                    .user-role-badge::after{ color: #111 !important; }
                }
            }

            &.support{ 
                .user-icon{ border-color: #b776ff; }
                .user-icon-badge{ background-color: #b776ff; }
            }

            &.partner{ 
                .user-icon{ border-color: #ff8664; }
                .user-icon-badge{ background-color: #ff8664; }
            }

            &.premium{ 
                .user-icon{ border-color: $themePurple; }
                .user-icon-badge{ background-color: $themePurple; 
                    .user-role-badge::after{ color: #fcbf19 !important; }
                }
            }

            &.tester{ 
                .user-icon{ border-color: #5865f2; }
                .user-icon-badge{ background-color: #5865f2; }
            }

            .user-icon-badge{
                width: 20px;
                height: 20px;
                border-radius: 100%;
                position: absolute;
                top: 30px;
                left: 30px;

                .user-role-badge{
                    font-family: 'Material Icons Round' !important;
                    margin-top: -2px;
                    margin-left: 2px;

                    &::after{
                        color: white;
                        font-size: 16px;
                    }

                    &.developer::after{ content: '\e86f'; }
                    &.communitymanager::after{ content: '\ef49'; }
                    &.moderator::after{ content: '\ea3c'; }
                    &.support::after{ content: '\ef73'; }
                    &.partner::after{ content: '\e5ca'; }
                    &.tester::after{ content: '\e868'; }
                    &.premium::after{ content: '\e838' }
                }
            }

            .user-icon{
                width: 48px !important;
                height: 48px !important;
                max-height: 48px !important;
                border-radius: 50%;
                margin-bottom: -5px;
                border: 3px solid transparent;
            }
        }

        .user-name{
            font-weight: bold;
             @include theme($themes){
                color: rgba(variable('pageText'), .9) !important;
            }
            margin-left: 10px;
            transition: .2s ease-in-out;
            min-width: 70px;
            display: block;

            &.developer{ color: $themePurple !important; }
            &.communitymanager{ color: #00acff !important; }
            &.moderator{ color: #4be9fd !important; }
            &.support{ color: #b776ff !important; }
            &.partner{color: #ff8664 !important; }
            &.tester{ color: #5865f2 !important; }
            &.premium{ color: $themePurple !important; }
        }

        .user-discriminator{
            margin-left: 10px;
            min-width: 40px;
            margin-top: -5px !important;
            display: block;
            font-size: 14px;
             @include theme($themes){
                color: rgba(variable('pageText'), .6) !important;
            }
        }
    }

    .logout:hover{
        color: $themeRed !important;
    }

    .navbar-dropdown{
        @include theme($themes){
            background-color: variable('pageBackground') !important;
        }
        box-shadow: 0 8px 8px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
       // background-color: darken($boxBackground, 3%);
        border: 1px solid rgba(255, 255, 255, .03) !important;
        border-radius: 6px !important;
        padding: 5px;
        max-width: 175px !important;

        opacity: 0;
        -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-transform: scale(0.75) translateY(-21px);
        -ms-transform: scale(0.75) translateY(-21px);
        transform: scale(0.75) translateY(-21px);
        -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
        transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out, height .3s ease;
        visibility: collapse !important;
        overflow: hidden;

      //  height: 144px;
          

        .navbar-item{
            background-color: transparent !important;
            transition: .2s ease-in-out;
            border-radius: 6px;
            @include theme($themes){
                color: variable('pageText') !important;
            }
            
            cursor: pointer;

            &:hover{
               // background-color: rgba(255, 255, 255, .04) !important;
                @include theme($themes){
                    color: variable('pageText') !important;
                    background-color: rgba(variable('boxBackgroundSecondary'), .04) !important;
                }
            }
        }

        hr.color{
            width: 85% !important;
            @include theme($themes){
                background-color: rgba(variable('boxBackgroundSecondary'), .08) !important;
            }
            //background-color: rgba(255, 255, 255, .08) !important;
            height: 1px !important;
            margin: 5px auto !important;
        }

        .colors{
            cursor: pointer;
            margin-bottom: 2px;

            &.is-active{
                @include theme($themes){
                    background-color: rgba(variable('boxBackgroundSecondary'), .04) !important;
                }
            }

            .color-circle{
                width: 18px;
                height: 18px;
                //border: 1px solid rgba(255, 255, 255, .08);
                border-radius: 100%;

                &.theme{
                    @include theme($themes){
                        background-color: variable('primaryBackground') !important;
                    }
                }

                &.purple{ background-color: $themePurple !important; }
                &.blue{ background-color: $themeBlue !important; }
                &.red{ background-color: $themeRed !important;}
                &.yellow{ background-color: $themeYellow !important; }
                &.aqua{ background-color: $themeAqua !important; }
                &.green{ background-color: $themeGreen !important; }
                &.blurple{ background-color: $themeBlurple !important; }
                &.light{
                    background-color: white !important;
                }
                &.dark{
                    background-color: darken($boxBackground, 15%) !important;
                }
            }
        }
    }
}
