.multi-select{
    padding: 5px;
    width: 100%;
    min-height: 44px;
    border-radius: 6px;
   // background-color: $pageBackground;

    @include theme($themes){
        box-shadow: variable('itemShadow') !important;
        background-color: variable('pageBackground') !important;
    }
  //  box-shadow: 0 6px 6px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);

    &.light-bg{
        box-shadow: none !important;
        background-color: transparent;
    }

    .multi-select-tags{
        padding: 5px 10px;
        padding-top: 0 !important;
        
        .multi-select-tag{
            font-size: 14px;
            // min-width: 75px;
            height: 24px;
            border-radius: 24px;
            padding-right: 10px;
            padding-left: 2px;
            border: 1px solid $themePurple;
            margin-right: 8px;
            margin-top: 5px;
            //margin-bottom: 5px;


            &.locked{
                .multi-select-tag-delete{
                    cursor: default !important;
                }
            }

            &:not(.disabled):hover{
                .multi-select-tag-delete{

                    .material-icons-round{
                        color: white !important;
                    }
                }
            }

            .multi-select-tag-delete{
                border-radius: 100%;
                width: 18px;
                height: 18px;
                background-color: $themePurple;
                cursor: pointer;

                &.no-hover{
                    cursor: not-allowed !important;
                }

                .material-icons-round{
                    font-size: 12px;
                    color: transparent !important;
                    transition: .2s ease-in-out;
                    text-align: center;
                    margin-left: 0.22em !important;
                }
            }

            .multi-select-tag-name{
                margin-left: 5px;
                font-size: 14px;
                max-width: 175px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .multi-select-dropdown{
        position: relative;

        .blanket{
            bottom: 0;
            left: 0;
            top: 0;
            right: 0;
            position: fixed;
            z-index: 1;
        }

        .multi-select-dropdown-button{
            border-radius: 100%;
            width: 24px;
            height: 24px;
            @include theme($themes){
                background-color: rgba(variable('boxBackgroundSecondary'), .08) !important;
                color: variable('pageText') !important;
            }
            //background-color: rgba(255, 255, 255, .08);
            cursor: pointer;
            margin-top: 5px;
            transition: background .2s ease-in-out;

            &:hover{
                @include theme($themes){
                    background-color: rgba(variable('boxBackgroundSecondary'), .12) !important;
                    color: variable('pageText') !important;
                }
                //background-color: rgba(255, 255, 255, .12);
                .material-icons-round{
                    @include theme($themes){
                        color: variable('pageText') !important;
                    }
                    color: white !important;
                }
            }

            .material-icons-round{
                font-size: 20px;
                @include theme($themes){
                    color: variable('pageText') !important;
                }
                transition: .2s ease-in-out;
                text-align: center;
                margin-top: 0.12rem;
                margin-left: 0.12rem;
            }
        }

        .multi-select-dropdown-menu{
            background-color: darken($boxBackground, 3%);
            @include theme($themes){
                background-color: variable('pageBackground') !important;
            }
            border: 1px solid rgba(255, 255, 255, .03) !important;
            border-radius: 6px;
            box-shadow: 0 8px 8px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
            margin-top: 5px;
            //border: none;
            margin-left: -20px;
            position: absolute;
            z-index: 2;
        }
    }
}

.multi-select-enter {
    opacity: 0;
    transform: scale(0.9);
}
.multi-select-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 200ms, transform 200ms;
}
.multi-select-exit {
    opacity: 1;
}
.multi-select-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 100ms, transform 100ms;
}

.react-select-container{
    .react-select__control{
        // background-color: lighten($pageBackground, 3%) !important;
        background-color: rgba(255, 255, 255, .055);

        @include theme($themes){
            background-color: rgba(variable('boxBackgroundSecondary'), .055) !important;
            box-shadow: variable('itemShadow') !important;
        }

        border-radius: 6px !important;
        border-color: transparent !important;
        cursor: pointer !important;

        &.react-select__control--is-focused{
            .react-select__input{
                color: white !important;
            }
        }

        .react-select__placeholder{
            @include theme($themes){
                color: rgba(variable('pageText'), .5) !important;
            }
           // color: rgba(white, .5);
        }

        .react-select__input{
            @include theme($themes){
                color: variable('pageText') !important;
            }
        }
    }

    .react-select__menu{
        box-shadow: none !important;
        padding: 10px !important;
        margin-top: -10px;

        .react-select__menu-list{
            padding-bottom: 0 !important;
        }

        .react-select__option{
            border-radius: 6px;
            cursor: pointer;
            transition: .2s ease-in-out;

            &.react-select__option--is-focused, &:active, &:focus{
                //background-color: rgba(255, 255, 255, .04) !important; 

                @include theme($themes){
                    background-color: rgba(variable('boxBackgroundSecondary'), .04) !important;
                }
            }
            &.react-select__option--is-selected{
                //background-color: rgba(255, 255, 255, .06) !important; 
                  @include theme($themes){
                    background-color: rgba(variable('boxBackgroundSecondary'), .06) !important;
                }
            }
            &.react-select__option--is-disabled{
                cursor: not-allowed !important;
                opacity: .6;
            }
        }
    }

}