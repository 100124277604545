#music-player-modal{

    .modal-content, .content-card{
        min-width: 1024px !important;
        max-width: 1024px !important;
        min-height: 682px !important;
        max-height: 682px !important;
    }

    .musicplayer-sidebar{
        width: 260px;
        padding: 5px;

        .album-art{
            border-radius: 6px;
        }

        .song-info{
            padding: 5px 5px 0 5px;

            .song-name{
                color: white;
                font-size: 20px;
                font-weight: bold;
            }

            .song-artist{
                color: rgba(white, .8);
                margin-top: -5px;
            }
        }

        .song-duration{
            float: right;
            font-size: 14px;
            color: white;
            font-weight: bold;
            margin-right: 15px;
            margin-top: -7px;
        }
    }

}