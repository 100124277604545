.page-loader,
.page-loader:after {
  border-radius: 50%;
  width: 7em;
  height: 7em;
}
.page-loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.8em solid #2e2e42;
  border-right: 0.8em solid #2e2e42;
  border-bottom: 0.8em solid #2e2e42;
  border-left: 0.8em solid #dddddd;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.page-container{
    position: relative;
}
.page-loader-container{
    position: relative;
    top: 0;
    left: 0;
    transition: .2s ease-in-out;

    &.fade{
        opacity: 0;
    }

    .columns{
        width: 100%;
        height: calc(100vh - 83px);
    }
}