 @-webkit-keyframes ring {
     0% {
         -webkit-transform: rotateZ(0);
     }

     1% {
         -webkit-transform: rotateZ(30deg);
     }

     3% {
         -webkit-transform: rotateZ(-28deg);
     }

     5% {
         -webkit-transform: rotateZ(34deg);
     }

     7% {
         -webkit-transform: rotateZ(-32deg);
     }

     9% {
         -webkit-transform: rotateZ(30deg);
     }

     11% {
         -webkit-transform: rotateZ(-28deg);
     }

     13% {
         -webkit-transform: rotateZ(26deg);
     }

     15% {
         -webkit-transform: rotateZ(-24deg);
     }

     17% {
         -webkit-transform: rotateZ(22deg);
     }

     19% {
         -webkit-transform: rotateZ(-20deg);
     }

     21% {
         -webkit-transform: rotateZ(18deg);
     }

     23% {
         -webkit-transform: rotateZ(-16deg);
     }

     25% {
         -webkit-transform: rotateZ(14deg);
     }

     27% {
         -webkit-transform: rotateZ(-12deg);
     }

     29% {
         -webkit-transform: rotateZ(10deg);
     }

     31% {
         -webkit-transform: rotateZ(-8deg);
     }

     33% {
         -webkit-transform: rotateZ(6deg);
     }

     35% {
         -webkit-transform: rotateZ(-4deg);
     }

     37% {
         -webkit-transform: rotateZ(2deg);
     }

     39% {
         -webkit-transform: rotateZ(-1deg);
     }

     41% {
         -webkit-transform: rotateZ(1deg);
     }

     43% {
         -webkit-transform: rotateZ(0);
     }

     100% {
         -webkit-transform: rotateZ(0);
     }
 }

 @-moz-keyframes ring {
     0% {
         -moz-transform: rotate(0);
     }

     1% {
         -moz-transform: rotate(30deg);
     }

     3% {
         -moz-transform: rotate(-28deg);
     }

     5% {
         -moz-transform: rotate(34deg);
     }

     7% {
         -moz-transform: rotate(-32deg);
     }

     9% {
         -moz-transform: rotate(30deg);
     }

     11% {
         -moz-transform: rotate(-28deg);
     }

     13% {
         -moz-transform: rotate(26deg);
     }

     15% {
         -moz-transform: rotate(-24deg);
     }

     17% {
         -moz-transform: rotate(22deg);
     }

     19% {
         -moz-transform: rotate(-20deg);
     }

     21% {
         -moz-transform: rotate(18deg);
     }

     23% {
         -moz-transform: rotate(-16deg);
     }

     25% {
         -moz-transform: rotate(14deg);
     }

     27% {
         -moz-transform: rotate(-12deg);
     }

     29% {
         -moz-transform: rotate(10deg);
     }

     31% {
         -moz-transform: rotate(-8deg);
     }

     33% {
         -moz-transform: rotate(6deg);
     }

     35% {
         -moz-transform: rotate(-4deg);
     }

     37% {
         -moz-transform: rotate(2deg);
     }

     39% {
         -moz-transform: rotate(-1deg);
     }

     41% {
         -moz-transform: rotate(1deg);
     }

     43% {
         -moz-transform: rotate(0);
     }

     100% {
         -moz-transform: rotate(0);
     }
 }

 @keyframes ring {
     0% {
         transform: rotate(0);
     }

     1% {
         transform: rotate(30deg);
     }

     3% {
         transform: rotate(-28deg);
     }

     5% {
         transform: rotate(34deg);
     }

     7% {
         transform: rotate(-32deg);
     }

     9% {
         transform: rotate(30deg);
     }

     11% {
         transform: rotate(-28deg);
     }

     13% {
         transform: rotate(26deg);
     }

     15% {
         transform: rotate(-24deg);
     }

     17% {
         transform: rotate(22deg);
     }

     19% {
         transform: rotate(-20deg);
     }

     21% {
         transform: rotate(18deg);
     }

     23% {
         transform: rotate(-16deg);
     }

     25% {
         transform: rotate(14deg);
     }

     27% {
         transform: rotate(-12deg);
     }

     29% {
         transform: rotate(10deg);
     }

     31% {
         transform: rotate(-8deg);
     }

     33% {
         transform: rotate(6deg);
     }

     35% {
         transform: rotate(-4deg);
     }

     37% {
         transform: rotate(2deg);
     }

     39% {
         transform: rotate(-1deg);
     }

     41% {
         transform: rotate(1deg);
     }

     43% {
         transform: rotate(0);
     }

     100% {
         transform: rotate(0);
     }
 }

.notification-tray{
    margin-right: 10px;

    span{
        font-size: 32px;
        color: white;
        transition: .2s ease-in-out;

        &:hover{
            -webkit-animation: ring 6s ease-in-out infinite;
            -webkit-transform-origin: 50% 4px;
            -moz-animation: ring 6s ease-in-out infinite;
            -moz-transform-origin: 50% 4px;
            animation: ring 6s ease-in-out infinite;
            transform-origin: 50% 4px;
        }
    }

    .navbar-dropdown{
        margin-top: -5px;
        border-radius: 6px;
        border: none !important;
        color: white !important;
        min-width: 225px;
        margin-left: -85px;
        padding: 0 !important;

        @include theme($themes){
            background-color: lighten(variable('pageBackground'), 3%) !important;
        }
        box-shadow: 0 8px 8px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);

        .notification-tray-header{
            width: 100% !important;
            padding: 10px 15px !important;
            font-size: 18px;
            @include theme($themes){
                background-color: variable('pageBackground') !important;
            }
            border-radius: 4px;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            text-align: center;
            font-weight: bold;
            color: white;
        }

        .notification-tray-body{
            max-height: 200px !important;
            padding: 5px;

            .notification-item{
                border-radius: 4px;
                padding: 5px 15px !important;
                color: rgba(white, .7);
                transition: .2s ease-in-out;

                span{
                    color: rgba(white, .7) !important; 
                    font-size: 24px !important;
                    margin-top: 5px;
                }

                &.is-error{
                    color: white !important;
                    background-color: transparent !important;
                }

                &.is-unread{
                    font-weight: bold;
                    color: white !important;

                    span{
                        color: white !important;
                    }
                }
                
                &:not(.is-error):hover{
                    cursor: pointer;
                    @include theme($themes){
                        background-color: variable('pageBackground') !important;
                        color: white;
                    }
                    span{
                        color: white !important;
                    }
                }
            }
        }
    }

    &.is-active{
        span{
            @include theme($themes){
                color: variable('primaryText') !important;
            }
        }
    }

    &:hover{
        span{
            cursor: pointer;

            @include theme($themes){
                color: variable('primaryText') !important;
            }
        }
    }
}

.notification-editor{
    @include theme($themes){
        background-color: variable('pageBackground');
        color: variable('pageText');
        box-shadow: variable('itemShadow');
    }
    padding: 10px 15px;
    border-radius: 4px;
    min-height: 200px;
    max-height: 200px;
    overflow: auto !important;
}

.notification-admin{
    .notification-title{
        font-size: 18px;
        color: white;
        font-weight: bold;
    }
}

.notification-modal{
    .notification-title{
        font-weight: bold;
        font-size: 24px;
        color: white;
        padding: 0 10px;
    }

    .notification-body{
        padding: 10px 15px;
        border-radius: 4px;
        margin-top: -10px;

        @include theme($themes){
            background-color: variable('pageBackground') !important;
            color: white;
        }
    }
}