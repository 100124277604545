.range-slider{
    padding: 5px;

    .range-slider-track{
        background-color: $pageBackground;
        height: 10px;
        width: 100%;
        border-radius: 10px;
        transition: .2s ease-in-out;
    }

    .range-slider-thumb{
        height: 20px;
        width: 20px;
        border-radius: 50%;
        box-shadow: 0 6px 6px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1) !important;
        border: none !important;
        outline: none !important;
        transition: background .2s ease-in-out;

        &.is-disabled{
            cursor: not-allowed !important;
        }

        @include theme($themes){
            background-color: variable('primaryBackground');
        }

        &:hover{
            @include theme($themes){
                background-color: darken(variable('primaryBackground'), 3%);
            }
        }
    }

}