.emoji-picker-wrapper{

    .emoji-picker-backdrop{
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 5;
        left: 0;
        top: 0;
    }

    .emoji-picker{
        width: 395px;
        //max-height: 500px;
        border-radius: 6px;
        background-color: $pageBackground;
        box-shadow: $shadow !important;
        position: relative;
        overflow: hidden;
        z-index: 10 !important;

        .emoji-picker-header{
            background-color: darken($boxBackground, 3%);
        }

        .emoji-picker-sidebar{
            width: 70px;
            height: 100%;
            background-color: lighten($boxBackground, 3%);
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
            padding: 10px;
            max-height: 412px;
            overflow-y: auto;

            .emoji-category-button{
                //padding: 10px 5px 5px 10px;
                border-radius: 6px;
                width: 50px;
                height: 50px;
                padding: 4px 3px 3px 4px;
                cursor: pointer;
                margin-bottom: 10px;

                color: rgba(white, .7);
                transition: .2s ease-in-out;
                &:not(.is-active):hover{
                    color: white;
                }

                &.is-active{
                    background-color: white;
                    
                    .material-symbols-rounded{
                        color: darken($boxBackground, 2%) !important;
                    }
                }

                .material-symbols-rounded{
                    font-size: 42px;
                }
            }

        }

        .search-container{
            position: absolute;
            top: 0;
            left: 70px;
            width: calc(100% - 70px);
            padding: 10px;
            background-color: rgba($pageBackground, .8);
            backdrop-filter: blur(32px);
            border-top-right-radius: 6px;

            .input{
                background-color: lighten($pageBackground, 5%) !important;
                box-shadow: $shadow;
                border-radius: 6px;
                color: white;
                &::placeholder{
                    color: rgba(white, .4);
                }
                border: none;

                &::-webkit-search-cancel-button {
                    color: white !important;
                }
            }
        }

        .emoji-list{
            padding: 15px;
            padding-top: 45px;
            max-height: 400px;
            overflow-y: auto;
            padding-right: 15px !important;
            padding-left: 15px;
            border-radius: 4px;

            .category-name{
                padding: 5px 0 5px 0;
                font-weight: bold;
            }

            .twemoji-button{
                padding: 6px;
                padding-bottom: 0;
                border-radius: 6px;
                transition: .2s ease-in-out;

                &:hover{
                    background-color: rgba(white, .06);
                    cursor: pointer;
                }

                .twemoji{
                    width: 36px;
                }
            }

            .custom-button{
                padding: 6px;
                    padding-bottom: 0;
                    border-radius: 6px;
                    transition: .2s ease-in-out;
                
                    &:hover {
                        background-color: rgba(white, .06);
                        cursor: pointer;
                    }
                
                    img {
                        width: 36px;
                        height: 36px;
                    }
            }
        }

        .highlighted-emoji-container{
            position: absolute;
            bottom: 0;
            left: 70px;
            padding: 10px 15px;
            border-bottom-right-radius: 6px;
            background-color: rgba($boxBackground, .8);
            backdrop-filter: blur(32px);
            width: calc(100% - 70px);
            overflow: hidden;

            .twemoji-hover{
                width: 36px;
                margin-top: 5px;
            }

            .highlighted-name{
                font-weight: bold;
                text-transform: uppercase;
                margin-top: 5px;
                width: 100%;
                overflow: hidden;
            }

            .highlighted-from{
                font-size: 14px;
                color: rgba(white, .8);
                margin-top: -5px;
            }
        }
    }
}